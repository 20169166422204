/*Redux connect*/
import { connect } from "react-redux";
import { getAccountManagerList, SubmitRequst } from "../../../../redux/actions/userManagement/submitOnbardingRequest";
import NewCustomer from "./NewCustomer";
import { openAlertPopup } from "../../../../redux/actions/Alert/alert.action";


const mapStateToProps = (state) => {

    return {
   submitting: state.submitCustomerDetails.loading,
     list:state.onboardingRequest.getAllCustomerList.data,
  // list: state.customerList.list,
   accountManagerList: state.onboardingRequest.accountMManagerList.data,
   myRights:state.myRights.myRights,

    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      submitRequest: (payload) => {
        return dispatch(SubmitRequst(payload))
      }, 
      openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      }, 
      getAccountManagerList: (payload)=>{
        return dispatch(getAccountManagerList(payload))
    } 
    //   getOnboardingList: (next=null)=>{
    //     return dispatch(getOnboardingList(next))
    // },   
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);


