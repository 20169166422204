import { Field, Form, Formik,useField,ErrorMessage } from "formik";
import React, { useId, useState } from "react";
import * as Yup from "yup";
import Asterisk from "../../../../components/asterisk/Asterisk";
import { BackButton, Submit } from "../../../../components/button/buttons";
import { TextInput } from "../../../../components/input/inputs";
import IMAGES from "../../../../assets/images/images";  
import { FormFieldLabel as Label,FormErrorLabel as ErrorLabel } from "../../../../components/label/Labels";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import styles from "./NewUser.module.css";
import { Alert } from "../../../../components/alert/alerts";
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import * as DOMPurify from 'dompurify';
import { capitalizeWords } from "../../../../utils/utils";
import Tooltip from "../../../../components/toolTip/toolTip";
import { IncidentServiceTypes, Incident_Service_Type_Values } from "../../../../utils/constants/incidentConstants";
import { FUNCTIONAL_ROLE_ENUM } from "../../../../utils/constants/userManagementConstant"
import SectionGuard from "../../../../hoc/guards/SectionGuard";
import CAN from "../../../../casl/can";
import { Action, Resource } from "../../../../casl/constants";
const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? styles.inputError
    : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
        <Asterisk />
      </div>
      <TextInput  {...field}  {...props} placeholder={placeholder}  style={inputStyle}   onChange={props.onChange} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const PhoneNumberTextField = ({ label, placeholder, ...props }) => {
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
      </div>
      <TextInput {...props} placeholder={placeholder} />
    </FieldWrapper>
  );
};

const CheckBox = ({ name, value, label,checked, onChange }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};



const NewUser = ({customerData,userData=null,isEdit,...props}) => {
  const {functionalRoles}=props;
  const [serviceType,setServiceType] = useState(customerData?.serviceSpecificDetails.map((item)=>item.serviceType.value))
  const hasIvcService = customerData?.serviceSpecificDetails.some(service => service.serviceType?.value === Incident_Service_Type_Values.IVC_Service);
  const hasIpxService = customerData?.serviceSpecificDetails.some(service => service.serviceType?.value === Incident_Service_Type_Values.IPX_Service);
  const rolesToShow = hasIpxService && serviceType?.length===1 && serviceType[0]==="ipx_service" ? functionalRoles : hasIvcService && serviceType?.length===1 && serviceType[0]==="ivc_service"
      ? functionalRoles.filter(role => role.id === FUNCTIONAL_ROLE_ENUM.INCIDENT_MANAGEMENT || role.id === FUNCTIONAL_ROLE_ENUM.USER_MANAGEMENT || role.id === FUNCTIONAL_ROLE_ENUM.DASHBOARD)
      : functionalRoles;
  const [showAddUser, setShowAddUser] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  let [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });

  let availedService = customerData?.serviceSpecificDetails?.map((detail)=>detail.serviceType);

  const getCPName=()=>{
    let cpName=null;
    let data=customerData?.serviceSpecificDetails?.filter((item) => {
      return item["serviceType"] == Incident_Service_Type_Values.IPX_Service;
    })
    if(data.length>0){
      cpName=data[0].cpName
    }
    return cpName;
  }

  let initialValues={}
  

  if (isEdit) {
    initialValues = {
      email: userData.email,
      fullName: userData.displayName,
      phoneNumber: null,
      assignModule: "manual",
      userType: "customer",
      functionalRoles: userData.functionalRoles,
      serviceType: userData?.customerUserMetaInfo?.myServices?.map((item)=>item.serviceType)    
    }
  }else{
    initialValues = {
      email: "",
      fullName: "",
      phoneNumber: null,
      assignModule: "manual",
      userType: "customer",
      functionalRoles: [],
      serviceType: availedService?.length == 1 ? availedService : []   // set default value if only one service is availed     
    }
  }


  const getEmailDomain=(email)=>{
    return email?email.split("@")[1]:"";
  }
  
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").max(100,"Email must be at most 100 characters").required("Required").test(
      {
          name:'Domain Validate',
          message:`User email domain must be same as the primary contact email domain (${getEmailDomain(customerData?.serviceSpecificDetails[0].primaryContacts[0].email)})`,
          test:function(value){
              let primaryEmailDomain= getEmailDomain(customerData?.serviceSpecificDetails[0].primaryContacts[0].email);
              let userEmailDomain=getEmailDomain(value);
              return primaryEmailDomain === userEmailDomain
          }
      }
  ),
    fullName: Yup.string()
      .min(2, "Too Short!")
      .max(100,"Name must be at most 100 characters")
      .required("Required"),
    phoneNumber: Yup.string().matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number").nullable(true),
    functionalRoles:Yup.array().min(1,"Please select at least one role."),
    serviceType:Yup.array().min(1,"Please select at least one service type.")
  });

  const close = () => setShowAddUser(false);
  const addUser=(obj)=>{
    props.createUser(obj).then((result) => {
      if (result.status) {
        setMessage({
          message: "New user added successfully!",
          image: IMAGES.success
        });
        setIsSubmit(false);
        close();
        props.openAlertPopup({
          message: "New user added successfully!",
          image: IMAGES.success
        });
        let searchKey = "";
        let type = USER_TYPE.CUSTOMER;
        let custID = customerData?.uniqueCustomerRefId;
        let next=null;
        props.getUser(type, custID, searchKey,next);
      } else {
        setIsSubmit(false);
        let error_msg = result?.error;
        close();
        setMessage({
          message: error_msg,
          image: IMAGES.error
        });
        props.openAlertPopup({
          message: error_msg,
          image: IMAGES.error
        });
      }
    })
      .catch((error) => {
        setIsSubmit(false);
        close();
        setMessage({
          message: "Something went wrong.",
          image: IMAGES.error
        });
        props.openAlertPopup({
          message: "Something went wrong.",
          image: IMAGES.error
        });
       // Alert.showAlert(message.message, message.image);
      //  setIsAlert(true);
      });
  }

  const updateUser=(obj)=>{
    let values={
      functionalRoles:obj.functionalRoles,
      myServices:obj.myServices,
      cpName:obj.cpName,
      userType:USER_TYPE.CUSTOMER
    }
    props.updateUserPermissions(values, userData.uid)
    .then((result) => {
      if (result.status) {
        setMessage({
          message: "User permissions updated successfully!",
          image: IMAGES.success
        });
        setIsSubmit(false);
        close();
        props.openAlertPopup({
          message: "User permissions updated successfully!",
          image: IMAGES.success
        });
        let searchKey = "";
        let type = USER_TYPE.CUSTOMER;
        let custID = customerData?.uniqueCustomerRefId;
        let next=null;
        props.getUser(type, custID, searchKey,next);
      } else {
        setIsSubmit(false);
        let error_msg = result?.error;
        close();
        setMessage({
          message: error_msg,
          image: IMAGES.error
        });
        props.openAlertPopup({
          message: error_msg,
          image: IMAGES.error
        });
      }
    })
    .catch((error) => {
      setIsSubmit(false);
        close();
        setMessage({
          message: "Something went wrong.",
          image: IMAGES.error
        });
        props.openAlertPopup({
          message: "Something went wrong.",
          image: IMAGES.error
        });
    });
  }


  return (
    <div>
      {isEdit ? (
        <div  onClick={() => setShowAddUser(true)}
        className={styles.addButtons}> <img className={styles.editrole} src={IMAGES.editrole} /></div>  
      ) : (
        <SectionGuard canAccess={CAN(Action.CREATE, Resource.RIGHTS)}>
         <button
          onClick={() => setShowAddUser(true)}
          className={styles.addButton}>
          Add New User
         </button>
        </SectionGuard>
      )}
      {showAddUser && (
        <Sidebar
          isOpen={showAddUser}
          headerTxt={isEdit?"Edit Access":"Add New User"}
          onClose={close}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={async (values, { resetForm }) => {    
              values.fullName = DOMPurify.sanitize(values?.fullName);
              values.phoneNumber = null; //DOMPurify.sanitize(values?.phoneNumber);
              values.cpName = values.serviceType?.indexOf(Incident_Service_Type_Values.IPX_Service)!= -1 ? getCPName() : null;  
              values.email = DOMPurify.sanitize(values?.email);             
              values.uniqueCustomerRefId = customerData?.uniqueCustomerRefId;
              let obj = JSON.parse(JSON.stringify(values));
              obj.myServices= values.serviceType.map(item => ({
                serviceType: item,
                cpName: item === Incident_Service_Type_Values.IPX_Service ? getCPName() : null
              }));
          
              delete obj.assignModule; 
              delete obj.serviceType; 
              setIsSubmit(true);
               
              if(isEdit){
                updateUser(obj);
              }else{
                addUser(obj);
              }
             
            }}
          >
            {({
              isValid,
              dirty,
              setFieldValue,
              values,
              handleChange,
              handleBlur,
            }) => (
              <Form className={styles.form}>
                <fieldset>
                  <TextField
                    label="Email Address"
                    name="email"
                    type="text"
                    placeholder="Input text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEdit}
                  />
                  <TextField
                    label="Full Name"
                    name="fullName"
                    type="text"
                    placeholder="Input text"
                    //onChange={handleChange}
                    onBlur={handleBlur}
                    onChange={(e)=>{
                      setFieldValue("fullName",capitalizeWords(e.target.value));
                      }}
                    disabled={isEdit}
                  />
                  {/*<PhoneNumberTextField
                    label="Mobile number"
                    name="phoneNumber"
                    type="text"
                    placeholder="XXXXX XXXXX"
                    onChange={handleChange}
                    onBlur={handleBlur}
            />*/}
            {availedService?.length>1 ? (
          <div className={styles.chackboxdiv}>
                 <div className={styles.chackboxtxt}>
                              Choose one or more service type{" "}
                              <Tooltip
                                text={"Choose one or more service type"}
                              />
                              <div></div>
                            </div>
                            <div>
                            <div className={styles.chackboxmain}>

                            {
                              IncidentServiceTypes?.map((item) => (
                                <CheckBox name="serviceType" value={item.value} label={item.label}
                                checked={values.serviceType.includes(item.value)}
                                onChange={(e) => {
                                const { checked } = e.target;
                                const newServiceTypes = checked? [...values.serviceType, item.value]: values.serviceType.filter((service) => service !== item.value);
                                setFieldValue("serviceType", newServiceTypes);
                                setServiceType(newServiceTypes)
                              }}
                                />
                              ))}
                              
                            </div>
                            <ErrorMessage name ="serviceType">
                              {(msg)=><ErrorLabel text={msg} />}
                            </ErrorMessage>
                            </div>
                 </div>
            ):null}
                  <div>
                    <h2 className={styles.assign}>Assign Modules</h2>
                    {/* <div className={styles.radioGroup}>
                      <div className={styles.radioField}>
                        <Field
                          id="manual"
                          type="radio"
                          name="assignModule"
                          value="manual"
                          className={styles.radioInput}
                        />
                        <label htmlFor="manual" className={styles.radioLabel}>
                          Select Manually
                        </label>
                      </div>

                      <div className={styles.radioField}>
                        <Field
                          id="admin"
                          className={styles.radioInput}
                          type="radio"
                          name="assignModule"
                          value="admin"
                          onClick={(val) => {
                            setFieldValue(
                              "functionalRoles",
                              functionalRoles.map((role) => role.id)
                            );
                          }}
                        />
                        <label htmlFor="admin" className={styles.radioLabel}>
                          Make an admin
                          <br /> (gives access to all modules)
                        </label>
                      </div>
                    </div> */}
                  </div>
                
                  <fieldset
                    disabled={values.assignModule === "admin"}
                    className={styles.checkboxaligngrid}
                  >
                    {rolesToShow?.map((role) => (
                      <CheckBox name="functionalRoles" value={role.id} label={role.name}
                      checked={values.functionalRoles.includes(role.id)}
                      onChange={(e) => {
                        const { checked } = e.target;
                        const newFunctionalRoles = checked
                          ? [...values.functionalRoles, role.id]
                          : values.functionalRoles.filter((r) => r !== role.id);
                        setFieldValue("functionalRoles", newFunctionalRoles);
                      }}
                      />
                    ))}
                  </fieldset>
                  <ErrorMessage name ="functionalRoles">
                    {(msg)=><ErrorLabel text={msg} />}
                  </ErrorMessage>
                </fieldset>

                <div>
                  <hr className={styles.separator} />
                  <ButtonWrapper>
                    <BackButton
                      className={styles.backButton}
                      style={styles.submit}
                      title={"Back"}
                      closePopup={close}
                      // onClose={close}
                    />
                    <Submit
                     
                      title={isEdit?"Save Change":"Submit"}
                      isSubmitting={isSubmit}
                      disabled={!isValid || !dirty}
                    />
                  </ButtonWrapper>
                </div>
              </Form>
            )}
          </Formik>
        </Sidebar>
      )}
      {/* {isAlert && (
                  
                    <Alert
                      image={message.image}
                      message={message.message}
                      onClose={setIsAlert}
                    />
                 
                )} */}
    </div>
  );
};

export default NewUser;