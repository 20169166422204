import { Link, useParams, useSearchParams } from "react-router-dom";
import IMAGES from "../../../../assets/images/images";
import {
  ChildContainer,
  Container,
} from "../../../../components/Container/Containers";
import { Search } from "../../../../components/search/search";
import NewCustomer from "../add/NewCustomer.container";
import styles from "./CustomerViewer.module.css";
import CustomerDetail from "../detail/CustomerDetail.container";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../components/loader/Loader";
import { EmptyContainer } from "../../../dashboard/graphs/component/component";
import { useNavigate } from "react-router-dom";
import SectionGuard from "../../../../hoc/guards/SectionGuard";
import CAN from "../../../../casl/can";
import { Action, Resource } from "../../../../casl/constants";



const CustomerViewer = ({ list,allCustomerList,myRights, isLoading,getAllCustomerList, getCustomerList,next }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const customerId = searchParams.get("customerId");

  let [searchKeyValue, setSearchKeyValue] = useState("");
  const getSearchKeyValue = (e) => {
    setSearchKeyValue(e.target.value);
  };

  const filterData = (key, value) => {
    let searchKey = searchKeyValue.trim();
    let cp_name = myRights?.cpName;
   getCustomerList(cp_name, searchKey);
  };
  const onKeyDownHandler = (e) => {   // filter data on enter event
    if (e.key === "Enter"){
      filterData();  
    }
  };

  useEffect(() => {
    if (!customerId) {  // if null then its listing page and call listing api
        getCustomerList();
    }
  }, [customerId]);
useEffect(() => {
  getAllCustomerList();
}, []);
  const removeCustomerId = useCallback(() => {
    setSearchParams((p) => {
      p.delete("customerId");
      return p;
    });
  }, [setSearchParams]);

  useEffect(() => {
    return () => {
      if (customerId) {
        removeCustomerId();
      }
    };
  }, [customerId, removeCustomerId]);
  if (customerId) {
    return <CustomerDetail onBack={removeCustomerId} customerId={customerId} />;
  }

  return (
    <>
      <React.Fragment>
        <ChildContainer>
          <div className={styles.headContainer}>
            <div className={styles.search}>
             <Search 
                 filterData={filterData}
                 getSearchKeyValue={getSearchKeyValue}
                 placeholder="Search"
                 onKeyDown={onKeyDownHandler}
              />
            </div>
            <SectionGuard canAccess={CAN(Action.CREATE,Resource.CUSTOMER_ACCESSR_REQUEST)}>
            <NewCustomer getCustomerList = {allCustomerList} />
            </SectionGuard>
          </div>
          {isLoading && <Loader />}
        {
          !isLoading && list.length==0?
          <EmptyContainer>No Customer Found</EmptyContainer>:<></>
        }
          <InfiniteScroll
            dataLength={list?.length} //This is important field to render the next data
            next={() =>  {
              return getCustomerList(myRights.cpName, searchKeyValue.trim(), next);
            }}
            hasMore={next}
            loader={
                <Loader />
            }
          >
          {list?.map((item, key) => (
            <div className={styles.container} key= {key}>
              <div className={styles.containerheader}>
                {/* <div className={styles.text}>{item.displayName}</div> */}
                {/* <Link to={`/user-management?customerId=${customer.id}`}> */}
                <Link to={`/user-management?customerId=${encodeURIComponent(item.uniqueCustomerRefId)}`}>
                  {/* <img src={IMAGES.chevronredright} alt="" /> */}
                  <div className={styles.text}>{item.displayName}</div>
                </Link>
              </div>

              <div className={styles.wrapper}>
               
                  <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Service Type</div>
                      {/* <div className={styles.sub}>{item.cpName}</div> */}
                   <div className={styles.sub}>{item?.serviceSpecificDetails?.map((detail)=>detail.serviceType?.label).join(' & ')}</div>
                    </div>
                  </div>
                  {/* <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Onboarding Date</div>
                      <div className={styles.sub}>{formatSecondsToDate(item.createdAt)}</div>
                    </div>
                  </div> */}
                  {/* <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Primary Contact Name</div>
                      <div className={styles.sub}>{item?.serviceSpecificDetails?.map((detail)=>detail.primaryContacts[0].name).join(' & ')}</div>
                    </div>
                  </div> */}
                  {/* <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Primary Contact Email</div>
                      <div className={styles.sub}>{item?.serviceSpecificDetails?.map((detail)=>detail.primaryContacts[0].email).join(' & ')}</div>
                    </div>
                  </div> */}
                   <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Onboarded By</div>
                      <div className={styles.sub}>{item.createdBy.name}</div>
                    </div>
                  </div>
                    <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Onboarded On</div>
                      <div className={styles.sub}>{formatSecondsToDate(item.createdAt)}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}  
             </InfiniteScroll>
             </ChildContainer>
      </React.Fragment>
    </>
  );
};

export default CustomerViewer;
