import { Sidebar } from '../../../../../components/sidebar/Sidebar';
import styles from '../addServiceRequest.module.css';

import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField } from 'formik';
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../../components/label/Labels";
import { FormTextArea,TextInput } from "../../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../../components/button/buttons";
import FileAttachment from "../../../fileAttachment/fileAttachment.container"
import { DetailContainer } from "../../../../../components/comment/comment";
import Asterisk from "../../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../../assets/images/images";
import * as DOMPurify from 'dompurify';
import Tooltip from '../../../../../components/toolTip/toolTip';
import { connect } from "react-redux";
import { openAlertPopup } from "../../../../../redux/actions/Alert/alert.action";
import { SubmitService } from "../../../../../redux/actions/incidentManagement/raiseServiceRequest.action";
import { getServiceList } from "../../../../../redux/actions/incidentManagement/serviceList.action";
import { SERVICE_REQUEST_TYPES_VALUES } from '../../../../../utils/constants/incidentConstants';
const IVC =(props) => {

    const [isSubmit, setIsSubmit] = useState(false);
     
   const FieldWrapper = ({ children }) => {
     return <div  className={styles.fieldWrapper}>{children}</div>;
   };
   const ButtonWrapper = ({ children }) => {
     return <div className={styles.buttonContainer}>{children}</div>;
   };
   
   const TextField = ({ label, placeholder, ...props }) => {
     const [field, meta, helpers] = useField(props);
     const hasError = meta.touched && meta.error ? true : false;
     const inputStyle = hasError
     ? styles.inputError
     : null;
     return (
       <FieldWrapper>
         <div>
           <Label text={label} style={styles.labelcompLevel}/>
           <Asterisk /><span className={styles.circlered}>
           {props.tooltiprquired == "true"? <span className={styles.tooltip} ><Tooltip text={props.tooltiptxt}/></span>:<></>}
            </span>
         </div>
         <TextInput {...field} {...props} placeholder={placeholder} style={styles.additionalStyle} />
         {hasError ? <ErrorLabel text={meta.error} /> : null}
       </FieldWrapper>
     );
   };
   
   const TextArea = ({ label, optional = false, ...props }) => {
     const [field, meta, helpers] = useField(props);
     const hasError = meta.touched && meta.error ? true : false;
     const inputStyle = hasError
       ? `${styles.input} ${styles.inputError}`
       : styles.input;
     return (
       <FieldWrapper>
         <div className={styles.labelcomment}>
         <Label text={label} optional={optional} style={styles.labelcompLevel}/>{props.name === "problemStatement" ?<Asterisk />:<></>}
         <span className={styles.circlered}>
           {props.tooltiprquired == "true"? <span className={styles.tooltip} ><Tooltip text={props.tooltiptxt}/></span>:<></>}
            </span>
         </div>      
         <FormTextArea {...field} {...props} style={styles.additionalStyle} />
         {hasError ? <ErrorLabel text={meta.error} /> : null}
       </FieldWrapper>
     );
   };
   
   const ImageUpload = ({
     setFieldValue,
     value,
     label = "Supporting Attachments",
     ...props
   }) => {
     const [field, meta, helpers] = useField(props);
     const hasError = meta.touched && meta.error ? true : false;
     return (
       <FieldWrapper >
        <div>
         <Label text={label} optional={false} style={styles.labelcompLevel}/> <span className={styles.circlered}>
           {props.tooltiprquired == "true"? <span className={styles.tooltip} ><Tooltip text={props.tooltiptxt}/></span>:<></>}
            </span></div>
         <FileAttachment name={props.name} value={value} setFieldValue={setFieldValue} />
         {hasError ? <ErrorLabel text={meta.error} /> : null}
       </FieldWrapper>
     );
   };
   let _initialValues = {
     issue: "",
     servicesImpacted:null,
     SingleMultiple:null,
     problemStatement: "",
     files: [],
   
   };
   
   const validationSchema = Yup.object({
    issue: Yup.string().max(100,"Title must be at most 100 characters").required("Required"), 
    problemStatement: Yup.string().max(1000,"Problem statement must be at most 1000 characters").required("Required"),
    files:Yup.array().max(3)
   });
   
   
   
   
   return (
    
     <Formik
       initialValues={_initialValues}
       enableReinitialize={true}
       validationSchema={validationSchema}
       validateOnChange
       onSubmit={async (values, { resetForm }) => {   
        setIsSubmit(true);
         let obj = {
          serviceRequestType: props.selectedForm,
          servicesImpacted:values.servicesImpacted, 
          singleOrmultipleMsisdn:values.SingleMultiple,
          uniqueCustomerRefId:props.myRights?.customerUserMetaInfo?.uniqueCustomerRefId,
          serviceRequestDetails: {
               title:values.issue,    
               problemStatement:values.problemStatement,
               attachement:values.files
          },
      };
         props.addService(obj).then((result) => {          
           if (result.status) {
            
             props.openAlertPopup({
               message: "Service request submitted successfully!",
               image: IMAGES.success
             });
             setIsSubmit(false);            
             resetForm();   
              props.onClose();
              props.getServiceList();
           } else {
        
             let error_msg = result?.error?.message ?? "Something went wrong.";
            
             props.openAlertPopup({
               message: error_msg,
               image: IMAGES.error,
               isFormOpen:true
             });
             setIsSubmit(false); 
           //  props.onClose();  
           }
         })
           .catch((error) => {
          
             props.openAlertPopup({
               message: "Something went wrong.",
               image: IMAGES.error,
               isFormOpen:true
             });
             setIsSubmit(false);     
       //      props.onClose();     
           });
   
       }}
     >
       {({ isValid,values,dirty, setFieldValue, resetForm }) => (  
        <>     
         <Form 
         id="ivc_service_request"
         className={styles.form}>          
            {props.selectedForm !== SERVICE_REQUEST_TYPES_VALUES.International_Voice_Services  ? <hr className={styles.separatortop} />:<></>}
            <div className={styles.note}>Please Provide The Details</div>
          <TextField
                 placeholder="Enter here"
                 label="Title"
                 name="issue"
                 type="text"
                 tooltiprquired="true"
                  tooltiptxt="Title"
               />
                <div className={styles.commentValidation}>100 characters</div>
             <div className={styles.textarea}>
           <TextArea
             label={"Problem Statement"}
             id="ProblemStatement"
             name="problemStatement"
             placeholder="Enter the description"
              tooltiprquired="true"
              tooltiptxt="Problem Statement"
           />
           </div>
           <div className={styles.commentValidation}>1000 characters</div>
           <br />
           <ImageUpload
             name="files"
             value={values.files}
             setFieldValue={setFieldValue}
             optional={false}
              tooltiprquired="true"
              tooltiptxt="Supporting Attachments"
           />   
         </Form>
          <footer className={styles.footer}>
          <hr className={styles.separator} />
          <ButtonWrapper>
            <BackButton className={styles.backButton}
              style={styles.submitt}
              title={"Cancel"} 
              closePopup={props.onClose} 
            />
            <Submit
              id="saveButton"
              className={styles.saveButton}
              style={styles.submitivc}
              title={"Submit"}
              disabled={!dirty || !isValid}
              form="ivc_service_request"
              isSubmitting={isSubmit}
            />
          </ButtonWrapper>  
          </footer>  
          </>
       )}
     </Formik>
   
   )
   };
   

    const mapStateToProps = (state) => {
      return {
        myRights:state.myRights.myRights,  
      };
    };
    
    const mapDispatchToProps = (dispatch) => {
      return {
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
          },
          addService: (payload) => {
            return dispatch(SubmitService(payload))
          },
          getServiceList: (next=null)=>{
            return dispatch(getServiceList(next))
        },
      
    
      };
    };
    
    export default connect(mapStateToProps, mapDispatchToProps)(IVC);