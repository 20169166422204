import React, { useState,useEffect } from 'react'
import { Breadcrumbs } from '../../../../../../components/breadcrumbs/Breadcrumbs';
import { Banner } from '../../../../../../components/banner/Banners';
import IMAGES from '../../../../../../assets/images/images';
import { useParams } from 'react-router-dom';
import { ChildContainer, Container } from '../../../../../../components/Container/Containers';
import styles from './onboardingRequestDetail.module.css';
import { useNavigate } from 'react-router-dom';
import  AddComment  from '../addComment/addComment.container';
import { formatDate } from '../../../../../../utils/arrayUtils';
import { Loader } from '../../../../../../components/loader/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ON_BOARDING_STATUS } from '../../../../../../utils/constants/userManagementConstant';

const OnboargingDetailList = ({data, onFileDownload}) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
  const navigate = useNavigate();
  const back = () => {
    navigate(`/user-management`);
  }
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const text = data?.onBoardingRequestDetail?.additionalInfoText
  const previewText = text?.slice(0, 100);
  const showViewMore = text?.length>100

  const downloadFile = (file, index,onDownload) => {   
    console.log("file",file)
    onDownload(file)
  }

    return (
     <div>
         <div className={styles.back}>
         <div>  <span><img className={styles.backbutton} src={IMAGES.backarrow} onClick={()=>back()} /></span><span className={styles.id}>{data?.onBoardingRequestDetail?.requestId}</span><span className={`${styles.status} ${styles[data?.onBoardingRequestDetail?.requestStatus?.value]}`}> {data?.onBoardingRequestDetail?.requestStatus?.label} </span></div>

      </div>
      <div className={styles.container}>
      <div className={styles.requestDetail}>Request Details</div>
      <div className={`${styles.listContent}`}>
             <div className={styles.list}>
                <div className={styles.label}>Requested By</div>
                <div className={styles.value}>{data?.onBoardingRequestDetail?.createdBy?.name}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Request Assign To</div>
                <div className={styles.value}>{data?.onBoardingRequestDetail?.requestAssignedTo?.label}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Requested Date</div>
                <div className={styles.value}>{formatDate(data?.onBoardingRequestDetail?.createdAt)}</div>
             </div>
             {(data?.onBoardingRequestDetail?.requestStatus?.value===ON_BOARDING_STATUS.APPROVED ||data?.onBoardingRequestDetail?.requestStatus?.value===ON_BOARDING_STATUS.REJECTED)&&(
              <>
                <div className={styles.list}>
                 <div className={styles.label}>{data?.onBoardingRequestDetail?.requestStatus?.value===ON_BOARDING_STATUS.APPROVED?"Approved By":"Rejected By"}</div>
                 <div className={styles.value}>{data?.onBoardingRequestDetail?.modifiedBy?.name}</div>
                </div>
                <div className={styles.list}>
                 <div className={styles.label}>{data?.onBoardingRequestDetail?.requestStatus?.value===ON_BOARDING_STATUS.APPROVED?"Approved On":"Rejected On"}</div>
                 <div className={styles.value}>{formatDate(data?.onBoardingRequestDetail?.modifiedAt)}</div>
                </div>
              </>
             )}
      </div>
      <div className={styles.label}>Additional Information</div>
      {/* <div className={styles.value}>{data?.onBoardingRequestDetail?.additionalInfoText}</div> */}
      <div className={styles.value}>
        {data?.onBoardingRequestDetail?.additionalInfoText?
        <>
        {isExpanded ? text : `${previewText}${showViewMore?"...":""}`}
             {showViewMore&&(
               <span className={styles.viewMore} onClick={handleToggle}>
             {isExpanded ? " View Less" : " View More"}
          </span>
        )}
        </>:"---"}
      </div>
      {/* {data?.onBoardingRequestDetail?.additionalInfoAttachments?.length>0 &&(
             <div className={styles.label}>Attachments</div>
             )} */}
             <div className={styles.parent}>
              {data?.onBoardingRequestDetail?.additionalInfoAttachments?.map((item,index)=>
              <div className={styles.attachment} key={index}>
              <img className={styles.attachlogo} src={IMAGES.attachmenticon} />
              <span className={styles.filename}>{item?.originalFileName}</span>
              <img className={styles.download} src={IMAGES.downloadfile}  onClick={() => downloadFile(item, index,onFileDownload)} />
              </div>)}
             </div>
     </div>
     </div>
    )
  };

  const OnboargingDetail = ({data}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
     <div>
      <div className={styles.container}>
      <div className={styles.requestDetail}>Customer Details</div>
        <div className={styles.list}>
            <div className={styles.label}>Organisation Legal name</div>
            <div className={styles.value}>{data?.onBoardingRequestDetail?.displayName}</div>
        </div>
        <div className={styles.requestDetail}>Availed Services</div>
        {data?.onBoardingRequestDetail?.requestDetails?.map((detail,index, array)=>
      <>
        <div key={index} className={`${styles.listContent} ${array.length > 1 && index !== array.length - 1 ? styles.borderbottom : ''}`} >
         <div className={styles.list}>
            <div className={styles.label}>Service Type</div>
            <div className={styles.value}>{detail?.serviceType?.label}</div>
         </div>
          <div className={styles.list}>
            <div className={styles.label}>Account Manager Contact</div>
            <div className={styles.value}>{detail?.customerAccountManagers?.map((item)=>item.name)}</div>
         </div>
         <div className={styles.list}>
            <div className={styles.label}>Customer Primary Contact</div>
            {detail?.customerPrimaryContact?.map((item)=>
            <>
            <div className={styles.value}>{item.name}{" "}<span className={styles.primaryemail}>({item.email})</span></div>
            </>
            )}
         </div>
         {detail?.cpName!==null&&(
           <div className={styles.list}>
           <div className={styles.label}>TADIG Code</div>
           <div className={styles.value}>{detail?.cpName}</div>
        </div>
         )}
        </div>
      </>
         )}
     </div>
     </div>
    )
  };
 

  const CommentDetail = ({data}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };
    const text = data?.commentMessage
    const previewText = text?.slice(0, 100);
    function getInitials(name) {
      return name.split(' ').map(word => word[0].toUpperCase()).join('');
    }
    const showViewMore = text?.length>100
    function getInitials(name) {
      return name.split(' ').map(word => word[0].toUpperCase()).join('');
    }
   
    return (
      <div className={styles.commentContainer}>
      <div className={styles.initalshead}>
        <div className={styles.initaltex}>
          <span className={styles.inital}>{getInitials(data?.createdBy?.name)}</span>
          <span className={styles.initatname}>{data?.createdBy?.name}</span>
        </div>
        <div className={styles.attachments}>
          Posted On:
          <span className={styles.date}>
            {formatDate(data?.createdAt)}
          </span>
        </div>
      </div>
      <div className={styles.commenttext}>
        {isExpanded ? text : `${previewText}${showViewMore?"...":""}`}
        {showViewMore&&(
          <span className={styles.viewMore} onClick={handleToggle}>
           {isExpanded ? " View Less" : " View More"}
          </span>
        )}
      </div>
    </div>
    );
  };


const OnboardingRequestDetail = (props)=> {

  let { requestId } = useParams();
  useEffect(() => {
    props.getOnBoardingRequestDetail(requestId);
    let next=null;
    props.getOnBoardingComments(requestId,next);
    window.scrollTo(0,0);
    return () => {
      props.clearOnboadingComments();
  };
  }, [requestId]);

  const fetchData = () => {
    if (props?.commentsList?.next) {
      props.getOnBoardingComments(requestId,props?.commentsList?.next);
    }
  };

  const onFileDownload=(file)=>{   
    props.downloadFiles(file.originalFileName,file.uniqueFileName)
    .then((result)=>{
        if(result.status){
          const fileData=result.file
          if(fileData?.length>0){
                window.open(fileData?.[0]);
          }
        }else{
          alert("Error while downloding file!");
        }
      }).catch((error)=>{
        alert("Something went wrong!")
      })
    }

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Onboarding Requests"}></Banner>
      <ChildContainer>
       {/* {props.requestDetails.map((item) => (
        <CommercialMlist data={item}/>
        ))} */}
          {props?.requestDetails.loading?
           <div className={styles.comentsLoader}>
           <Loader />
         </div>:
         <>
          <OnboargingDetailList data={props.requestDetails} onFileDownload={onFileDownload}/>
          <OnboargingDetail data={props.requestDetails}/>
         </>
         }
          <div  className={styles.commentsection}>
            <div className={styles.comtext}> Comment History</div>
            <div>
              {!props?.requestDetails.loading&&(
              <AddComment source={'incDetail'} data={props.requestDetails?.onBoardingRequestDetail}/>
              )}
            </div>
          </div>
          {/* <div className={styles.alert}>Request has been created and assigned to Commercial Manager.  </div> */}

          {props?.commentsList?.isLoading && props?.commentsList?.comments.length == 0? (
          <div className={styles.comentsLoader}>
            <Loader />
          </div>
           ) : props?.commentsList?.comments.length  == 0 ? (
            <div className={styles.emptydiv}>No Records Found</div>
           ) : (
           <InfiniteScroll
            dataLength={props?.commentsList?.comments.length} //This is important field to render the next data
            next={() => {
            fetchData();
             }}
            hasMore={props?.commentsList?.next}
            loader={<Loader />}
          >
          {props?.commentsList?.comments?.map((item) => (
            <CommentDetail data={item}/>
          ))}
        </InfiniteScroll>
      )}
      </ChildContainer>
    </Container>
  )
};

export default OnboardingRequestDetail;
