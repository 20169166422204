import React from "react";
import IMAGES from "../../assets/images/images"
import styles from "./breadcrumbs.module.css"
import { useNavigate } from "react-router-dom";

export const Breadcrumbs=(props)=>{   
    const navigate = useNavigate(); 
    const goToUrl = (url) => {       
        navigate(url);
      };
    return(
        <div className={styles.conatiner}>
            {
                  props.options.map((item, index)=>{
                    return(
                        <div className={styles.item} key={index}>
                            <span onClick={()=>{
                                            goToUrl(item.link)
                                    }}>{item.label}</span>
                            {
                                props.options.length!=index+1?
                                <img src={IMAGES.chevron} className={styles.img} />:
                                <></>
                            }
                        </div>
                    )
                })
            }
      
        </div>
    )
}