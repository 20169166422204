import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Wrapper, TextField, TextArea, SelectField ,FormTitle,ImageUpload} from "../controllers/controllers";
import * as Yup from "yup";
import styles from "../controllers/controllers.module.css";
import { EditButton, Submit } from "../../../../components/button/buttons";
import IMAGES from "../../../../assets/images/images";
import {connect} from "react-redux";
import {setIncidentPreviewMode} from "../../../../redux/actions/incidentManagement/raiseIncident.action";
import { Yes_No_Options,IVC_CALL_FAILURE_SUBTYPE_OPTIONS,Priority_Options, ADD_TICKET_ACTION} from "../../../../utils/constants/incidentConstants";
import {getLabelFromValue} from "../../../../utils/arrayUtils"
import {SubmitIncident} from "../../../../redux/actions/incidentManagement/raiseIncident.action";
import {openAlertPopup} from "../../../../redux/actions/Alert/alert.action";
import {getIncidentList} from "../../../../redux/actions/incidentManagement/incidentList.action";
import { incidentDownloadFiles } from "../../../../redux/actions/fileAttachment/fileAttachment.actions";



const IVCSIPTrunkConnectivityIssues = ({ service_type, issue_type, failure_type,parentFormData,actionType, incidentData, ...props }) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const onFileDownload=(file)=>{
        props.incidentDownloadFile(file.originalFileName,file.uniqueFileName,file.messageId,file.attachmentId,file.graphInternetMessageId)
        .then((result)=>{
            if(!result.status){
                alert("Error while downloding file!");
            }
        }).catch((error)=>{
        alert("Something went wrong!")
        })
    }
    let initialValues = {}
    if(actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS){
        initialValues = {
        issue_summary: incidentData?.issueDetails?.title,
        ip_end_points:incidentData?.issueDetails?.IPEndPoints,
        problem_statement:incidentData?.issueDetails?.problemStatement,
        date_time:incidentData?.issueDetails?.issueStartedDateTime,
        sip_trace_text:incidentData?.issueDetails?.SIPTracesText,
        sip_trace_file:incidentData?.issueDetails?.SIPTracesAttachment,
        priority:incidentData?.issueDetails?.priority,
        additional_information:incidentData?.issueDetails?.additionalInformation
        };
    }else{
      initialValues ={
        issue_summary: '',
        ip_end_points:'',
        problem_statement:'',
        date_time:'',
        sip_trace_text:'',
        sip_trace_file:[],
        priority:'',
        additional_information:'',
        impactedSubscriber:null,
     };
    }

    const validationSchema = Yup.object().shape({
        issue_summary: Yup.string().max(100, "Issue summary must be at most 100 characters").required("Required"),
        ip_end_points: Yup.string().required("Required"),
        problem_statement:Yup.string().max(1000, "Problem statement must be at most 1000 characters").required("Required"),
        date_time:Yup.string().required("Required"),
        sip_trace_text:Yup.string().max(1000, "SIP Traces must be at most 1000 characters"),
        sip_trace_file:Yup.array().max(3),
        priority: Yup.string(),
        additional_information:Yup.string().max(1000, "Text must be at most 1000 characters")
    })
    

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                if(props.isIncidentPreviewModeOn){
                    setIsSubmit(true);                                 
                let obj = {
                    incidentServiceType: parentFormData.service_type,
                    incidentFaultType: parentFormData.issue_type,
                    failureRelatedType: parentFormData.failure_type,
                    impactedSubscriber: parentFormData.impacted_subscriber,
                    uniqueCustomerRefId:props.myRights?.customerUserMetaInfo?.uniqueCustomerRefId,
                    issueDetails: {
                            title: values.issue_summary,
                            IPEndPoints: values.ip_end_points,
                            problemStatement: values.problem_statement,
                            issueStartedDateTime: values.date_time,
                            SIPTracesText: values.sip_trace_text,
                            SIPTracesAttachment: values.sip_trace_file,
                            priority: values.priority,
                            additionalInformation: values.additional_information
                    }               
                  };      
                props.addIncident(obj).then((result) => {        
                  if (result.status) {
                    props.openAlertPopup({
                      message: "New incident has been created.",
                      image: IMAGES.success
                    });          
                    setIsSubmit(false);
                    props.closeForm();
                    resetForm();
                    props.getIncidentList();
                  } else {
                    let error_msg = result?.error;
                    props.openAlertPopup({
                      message: error_msg,
                      image: IMAGES.error,
                      isFormOpen:true
                    });  
                    setIsSubmit(false);
                   // props.closeForm();
                  }
                })
                .catch((error) => {
                  props.openAlertPopup({
                    message: "Something went wrong.",
                    image: IMAGES.error,
                    isFormOpen:true
                  }); 
                   setIsSubmit(false);
                 //  props.closeForm();
                });
            }else{
                props.setIncidentPreviewMode(true);
            }
            }}
        >
            {({ isValid, dirty, setFieldValue, values, handleChange, handleSubmit, handleBlur, resetForm }) => (
                <>
                    <FormTitle
                        title={actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS?null:(props.isIncidentPreviewModeOn?"Please Check The Details":"Please Provide The Details")}
                    />
                    <Form 
                        id="ivc_sip_trunk_connectivity_issues"
                        className={styles.form}
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            label="Title"
                            type="text"
                            name="issue_summary"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Title"}
                            textLimit={"100 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextField
                            label="IP End Points"
                            name="ip_end_points"
                            isMandatory={true}
                            type="text"
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Please enter Vodafone Signalling IP and your end signalling IP, Traces if possible"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <TextArea
                            label="Problem Statement"
                            name="problem_statement"
                            isMandatory={true}
                            placeholder="Enter the issue you are facing..."
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Enter the background of the issue that the customer is experiencing"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextField
                            label="Date & Time of Issue Started"
                            name="date_time"
                            isMandatory={true}
                            type="datetime-local"
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Date & Time of Issue Started"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <SelectField
                            label={"Priority"}
                            name="priority"
                            placeholder="Choose priority"
                            options={Priority_Options}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            showTooltip={true}
                            tooltipText={"Priority"}
                            isExpandedField={false}
                            isMandatory={false}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <TextArea
                            label="SIP Traces or Attachments"
                            name="sip_trace_text"
                            isMandatory={false}
                            placeholder="Enter the issue you are facing..."
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"SIP Traces or Attachments"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <ImageUpload
                            name="sip_trace_file"
                            value={values.sip_trace_file}
                            setFieldValue={setFieldValue}
                            isMandatory={false}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                            onFileDownload={onFileDownload}
                            actionType={actionType}
                        />
                        <TextArea
                            label="Additional Information"
                            name="additional_information"
                            isMandatory={false}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Additional Information"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                    </Form>
                    {actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS?
                         <footer className={`${styles.footer} ${actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS ?styles.closedetalpopup:""}`}>
                            <EditButton
                            className={styles.backButton}
                            title={"Close"}
                            style={styles.btn}
                            onClick={()=>{
                                props.setIncidentPreviewMode(false);
                                props.closeForm();
                            }}/>
                         </footer>:
                    <footer className={styles.footer}>
                        {
                           props.isIncidentPreviewModeOn? 
                           <EditButton
                            className={styles.backButton}
                            title={"Edit"}
                            style={styles.btn}
                            onClick={()=>props.setIncidentPreviewMode(false)}
                        />:<EditButton
                            className={styles.backButton}
                            title={"Cancel"}
                            style={styles.btn}
                            onClick={()=>{
                                props.setIncidentPreviewMode(false);
                                props.closeForm();
                            }}
                        />
                        }
                        <Submit
                            id="saveButton"
                            title={props.isIncidentPreviewModeOn?"Submit":"Preview"}
                            form="ivc_sip_trunk_connectivity_issues"
                            style={styles.btn}
                            isSubmitting={isSubmit}
                            disabled={!isValid || !dirty}
                        />
                    </footer>
}
                </>
            )}

        </Formik>
    )

}

const mapStateToProps = (state)=>{
    return {
        isIncidentPreviewModeOn:state.IncidentManagement.isIncidentPreviewModeOn,
        myRights:state.myRights.myRights,         
    }
  }

const mapDispatchToProps = (dispatch)=>{
    return {
        setIncidentPreviewMode: (payload) => {
            return dispatch(setIncidentPreviewMode(payload))
        },
        addIncident: (payload) => {
            return dispatch(SubmitIncident(payload))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
          },
          getIncidentList: (next=null)=>{
              return dispatch(getIncidentList(next))
          },
        incidentDownloadFile:(originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId)=>{
            return dispatch(incidentDownloadFiles(originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId))
        },
    }
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(IVCSIPTrunkConnectivityIssues);

