import {
  getAuth,
  signOut,
  getIdToken,
  signInWithPopup,
  OAuthProvider,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  setPersistence,
  browserSessionPersistence,
  multiFactor
} from "firebase/auth";
import { TENANT_ID } from '../configuration/general-config'
import { SIGN_IN_ERROR_MESSAGES, SIGN_IN_STATUS } from "./constants";
import { app } from "../configuration/gcp-identity-platform-config";

export const vfLogin = () => {
  return new Promise((resolve, reject) => {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: TENANT_ID,
      prompt: 'select_account'
    });

    const auth = getAuth(app);
    signInWithPopup(auth, provider)
      .then((result) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
        // Handle error.
      });
  });
}

export function logOut() {
  return new Promise((resolve, reject) => {
    const auth = getAuth(app);
    signOut(auth)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject();
      });
  });
}

export async function getAccessToken() {
  const auth = getAuth(app);
  const user = auth.currentUser;
  if (user) {
    const token = await getIdToken(user);
    return token;
  } else {
    return "";
  }
}
export async function getAccessTokenForceRefresh() {
  const auth = getAuth(app);
  const user = auth.currentUser;
  if (user) {
    const token = await getIdToken(user, true);
    return token;
  } else {
    return "";
  }
}

// export function loginWithEmailIdPassword(_email, _password) {
//   return new Promise((resolve, reject) => { 
//     const auth = getAuth(app);
//     signInWithEmailAndPassword(auth, _email, _password)
//     .then((userCredential) => {
//       // Signed in            
//         const { emailVerified } = userCredential.user;
//         const resolveStatus = emailVerified ? SIGN_IN_STATUS.SUCCESS : SIGN_IN_STATUS.UNVERIFIED_EMAIL;
//         resolve(resolveStatus);
//     }) .catch((error) => {  
//       reject(SIGN_IN_STATUS.INVALID_CREDENTIALS);
//     })
//   })

// }

export const loginWithEmailIdPassword = (_email, _password) => { //CUP-666
  return new Promise((resolve, reject) => {
    const auth = getAuth(app);
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "captcha-container",
          {
            "size": "invisible",
            "callback": function (response) {

            },
            "expired-callback": function () {

            },
          }
        );

        // window.recaptchaVerifier.render()
        //   .then(function (widgetId) {
        //     window.recaptchaWidgetId = widgetId;
        //   });
      }
    } catch (error) {
      console.error("Recaptcha error", error)
      reject(SIGN_IN_STATUS.RECAPTCHA_ISSUE)
      //window.recaptchaVerifier?.clear();
    }

    // Signin with Email and Password
    signInWithEmailAndPassword(auth, _email, _password)
      .then((userCredential) => {
        // Signed in            
        const { emailVerified } = userCredential.user;
        const resolveStatus = emailVerified ? SIGN_IN_STATUS.MFA_DISABLED : SIGN_IN_STATUS.UNVERIFIED_EMAIL
        resolve(resolveStatus);
      })
      .catch((error) => {
        console.error("signInWithEmailAndPassword", error)

        switch (error.code) {
          case "auth/multi-factor-auth-required":
            handleMultiFactorAuth(auth, error)
              .then(() => {
                resolve(SIGN_IN_STATUS.SUCCESS);
              })
              .catch((error) => {
                reject(error);
              });

            break;
          case "auth/too-many-requests":
            reject(SIGN_IN_STATUS.TOO_MANY_REQUEST);
            break;
          case "auth/invalid-credential":
            reject(SIGN_IN_STATUS.INVALID_CREDENTIALS);
            break;
          default:
            reject(SIGN_IN_STATUS.RECAPTCHA_ISSUE);
            break;
        }
      }
      );

  });

}

const handleMultiFactorAuth = (auth, error) => {
  return new Promise((resolve, reject) => {
    const resolver = getMultiFactorResolver(auth, error);
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    let verificationId;

    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
      .then((id) => {
        verificationId = id;
        const promptMessage = `Please enter the verification code sent on ${resolver.hints[0].phoneNumber} (${resolver.hints[0].displayName}), via SMS.`;
        const verificationCode = window.prompt(promptMessage);
        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        return resolver.resolveSignIn(multiFactorAssertion);
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error("handleMultiFactorAuth", error)
        switch (error.code) {
          case "auth/invalid-verification-code":
            reject(SIGN_IN_STATUS.INVALID_VERIFICATION_CODE);
            break;
          case "auth/missing-code":
            reject(SIGN_IN_STATUS.MISSING_VERIFICATION_CODE);
            break;
          default:
            reject(SIGN_IN_STATUS.RECAPTCHA_ISSUE);
            break;
        }
        //window.recaptchaVerifier?.clear();
      });
  });
}



export const complete2FAEnrollment = async (verifyId, OTP, deviceName) => {
  const auth = getAuth(app)
  const multiFactorUser = multiFactor(auth.currentUser)
  // Ask user for the verification code.
  let cred = PhoneAuthProvider.credential(verifyId, OTP);
  let multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  // Complete enrollment.
  return await multiFactorUser.enroll(multiFactorAssertion, deviceName);
}


export const enrollSecondFactor = (phone) => { //CUP-666
  return new Promise((resolve, reject) => {
    const auth = getAuth(app);
    try {
      if (!window.recaptchaVerifier2FA) {
        window.recaptchaVerifier2FA = new RecaptchaVerifier(
          auth,
          "captcha-container-2fa",
          {
            "size": "invisible",
            "callback": function (response) {
            },
            "expired-callback": function () {
            }
          }
        );
      }

      multiFactor(auth.currentUser)
        .getSession()
        .then(session => {
          // Specify the phone number and pass the MFA session.
          var phoneInfoOptions = {
            phoneNumber: phone,
            session: session
          };
          let phoneAuthProvider = new PhoneAuthProvider(auth);
          // Send SMS verification code.

            phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier2FA).then((verificationId) => {
              //console.log("verificationId", verificationId);
              resolve(verificationId)
            }).catch((error) => {
              reject(error)
            })

        }).catch((error) => {
          reject(error)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export const enableRecaptcha = () => { //CUP-666
  try {
    const auth = getAuth(app)
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "captcha-container",
        {
          "size": "invisible",
          "callback": function (response) {
          },
          "expired-callback": function () {
          }
        }
      );


      /*window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
      });*/
    }
  } catch (error) {
    console.log("error", error);
    //window.recaptchaVerifier?.clear();
    alert(SIGN_IN_ERROR_MESSAGES.RECAPTCHA_ISSUE);
  }
}

