import React, { useState,useEffect} from "react";
import styles from "./tabs.module.css"
import { ChildContainer } from "../Container/Containers";


export const SubTabs = (props) => {
    const [activeTab, setActiveTab] = useState(props.defaultActiveTab);
    useEffect(() => {
        props.getSubActiveTab(activeTab);        
    }, [activeTab]);
    const handleTab = (key) => {
        setActiveTab(key);      
    }
    
    const filteredTabOptions = props.option.filter(tab => tab.canAccess);

    return (
        <div className={styles.conatiners}>
            {
                filteredTabOptions.map((item,index) => {
                    let _class = `${styles.items}`
                    if (activeTab === item.key) {
                        _class = `${styles.items} ${styles.activetab}`
                    }
                    return (
                        <div key={index} className={_class} onClick={() => handleTab(item.key)}>
                             {item.label}
                        </div>
                    )
                })
            }
        </div>
    )
}