import React, { useEffect, useRef, useState,useMemo } from "react";
import styles from "../graphs.module.css";
import { Formik, Form } from 'formik';
import { SelectField, TextField, TimeWindowField } from "../component/component";
import moment from 'moment';
import {sortArray} from "../utlis";
import { connect } from "react-redux";
import { getPeakInOutByRouter, updatePeakInOutByRouterFilters } from "../../../../redux/actions/dashboard/weatherMap/peakInOutByRouter.action";
import * as Constants from "../constants";
import Overlay from "../overlay/Overlay";
import * as Yup from "yup";
import {USER_TYPE} from "../../../../utils/constants/userConstants";
import { getLabelFromValue } from "../../../../utils/arrayUtils";

const FilterContainer = ({ filter,defaultFilters,resetFilters,applyFilters,cpNamesDrodownOptions,serviceTypesDrodownOptions, ...props }) => {
    let[isReset,setIsReset]=useState(true);
   
    let cpNameOptions  = sortArray(cpNamesDrodownOptions?cpNamesDrodownOptions:[]).map(key => ({name: key, value: key}));
    let serviceTypeOptions  = sortArray(serviceTypesDrodownOptions?serviceTypesDrodownOptions:[]).map(key => ({name: key?.toUpperCase(), value: key}));

    let _initialValues = filter;
    const TimeGranularityOption = [Constants.MINUTE5, Constants.HOUR, Constants.DAY, Constants.MONTH]
    const timeWindowOption = Constants.TIME_WINDOW_OPTIONS;
    const validationSchema = Yup.object({
        time_granularity: Yup.string().required("Required"),
        time_window: Yup.string().required("Required"),
        end_date: Yup.date().when("time_window", (time_window, schema) => {
            if (time_window == Constants.CUSTOM) {
                return schema.required("Required.").min(
                    Yup.ref('start_date'),
                    "End date can't be before Start date."
                ).test(
                    {
                        name:'max',
                        message:'End date can not be more than six months after the start date.',
                        test:function(value){
                            let sixMonthLaterDate = new Date(this.resolve(Yup.ref('start_date')));
                            sixMonthLaterDate.setMonth(sixMonthLaterDate.getMonth() + 6);
                            return new Date(value) <= sixMonthLaterDate
                        }
                    }
                )
            }
            return schema
        }),
        start_date: Yup.date().when("time_window", (time_window, schema) => {
            if (time_window == Constants.CUSTOM) {
                return schema.required("Required")
            }
            return schema
        }),
        cp_names : Yup.string().required("Required"),
        service_types : Yup.string().required("Required"),
    });

    useEffect(()=>{
        let obj1={
            time_granularity:filter?.time_granularity,
            time_window:filter?.time_window,
            end_date:filter?.end_date,
            start_date:filter?.start_date,
            service_types:filter?.service_types,
            router:filter?.router,
            cp_names:filter?.cp_names,
        }
        let obj2={
            time_granularity:defaultFilters?.time_granularity,
            time_window:defaultFilters?.time_window,
            end_date:defaultFilters?.end_date,
            start_date:defaultFilters?.start_date,
            service_types:defaultFilters?.service_types,
            router:defaultFilters?.router,
            cp_names:defaultFilters?.cp_names,
        }
        if(JSON.stringify(obj1) === JSON.stringify(obj2)){
            setIsReset(true);
        }else{
            setIsReset(false);
        }
    },[filter])

    const handleCPChangeChange=(value)=>{
       
    }


    const handleServiceTypeChange=(value)=>{
      
    }

    return (
        <div>
            <Formik
                initialValues={_initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    if(values.time_window !==Constants.CUSTOM){
                        values.end_date=moment().format('YYYY-MM-DD');
                        if(values.time_window===Constants.LAST_24_HOURs){
                            values.start_date=moment().subtract(1, 'd').format('YYYY-MM-DD');
                        }
                        if(values.time_window===Constants.DAY1){
                            values.start_date=moment().subtract(1, 'd').format('YYYY-MM-DD');
                        }
                        if(values.time_window===Constants.DAYS7){
                            values.start_date=moment().subtract(7, 'd').format('YYYY-MM-DD');
                        }
                        if(values.time_window===Constants.MONTH3){
                            values.start_date=moment().subtract(3, 'months').format('YYYY-MM-DD');
                        }
                        if(values.time_window===Constants.MONTH6){
                            values.start_date=moment().subtract(6, 'months').format('YYYY-MM-DD');
                        }
                    }
                    applyFilters(values);
                }}
            >
                {({ isValid, dirty, values,setFieldValue }) => (

                    <Form>
                         <div className={styles.sectionContainer}>
                        </div>
                            <React.Fragment>
                                <TimeWindowField
                                    title={"Time Window"}
                                    name="time_window"
                                    options={timeWindowOption}
                                />
                                {
                                    values?.time_window == Constants.CUSTOM ?
                                        <React.Fragment>
                                            <TextField
                                                label="Start Date"
                                                name="start_date"
                                                type="date"
                                                placeholder="Top Destinations"
                                            />
                                            <TextField
                                                label="End Date"
                                                name="end_date"
                                                type="date"
                                                placeholder="Top Destinations"
                                            />
                                        </React.Fragment> : <></>
                                }
                            </React.Fragment>
                            <React.Fragment>
                                <SelectField
                                    title={filter?.user_type===USER_TYPE.INTERNAL? "TADIG ID":"Region"}
                                    name="cp_names"
                                    options={cpNameOptions}
                                    onChange={(value)=>{
                                        handleCPChangeChange(value);
                                    }}
                                    isMandatory={true}
                                />

                            </React.Fragment> 
                               
                        <SelectField
                            title={"Service Type"}
                            name="service_types"
                            options={serviceTypeOptions}
                            onChange={handleServiceTypeChange}
                            isMandatory={true}
                        />
                        <div className={styles.formikBtnContainer}>
                          <button className={styles.formikBtnClear} disabled={isReset} onClick={resetFilters}>Reset</button>
                           <button  type="submit" className={styles.formikBtnSubmit} >Apply</button>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

const SelectedFilters=({selectedFiletrs,...props})=>{
    return(
        <div className={styles.selectedFilters}>
            <div className={styles.selectedItem}>
                <span className={styles.label}>Time Window</span><span className={styles.value}>{ getLabelFromValue(Constants.TIME_WINDOW_OPTIONS,selectedFiletrs?.time_window)}</span>
            </div>
            {
                selectedFiletrs.time_window == Constants.CUSTOM ?
                    <React.Fragment>
                        <div className={styles.selectedItem}>
                            <span className={styles.label}>Start Date</span><span className={styles.value}>{selectedFiletrs?.start_date}</span>
                        </div>
                        <div className={styles.selectedItem}>
                            <span className={styles.label}>End Date</span><span className={styles.value}>{selectedFiletrs?.end_date}</span>
                        </div>
                    </React.Fragment> : <></>
            }
           
            <React.Fragment>
               
                <div className={styles.selectedItem}>
                    <span className={styles.label}>{selectedFiletrs?.user_type === USER_TYPE.INTERNAL ? "TADIG ID":"Region"}</span><span className={styles.value}>{selectedFiletrs?.cp_names ? selectedFiletrs?.cp_names : "Not Selected"}</span>
                </div> 
                <div className={styles.selectedItem}>
                    <span className={styles.label}>Service Type</span><span className={styles.value}>{selectedFiletrs?.service_types?selectedFiletrs?.service_types?.toUpperCase():"Not Selected"}</span>
                </div>
            </React.Fragment>
           
        </div>
    )
}

const WeatherMapCommonFilter = (props) => {
    const childRef = useRef();
    let _cpNames="";
    let _service_types="";
    if(props.params.hasOwnProperty('service_types')){
        _service_types=props.params.service_types;
    }
    if(props.params.hasOwnProperty('cp_names')){
        _cpNames=props.params.cp_names;
    }

     let defaultFilters={
        time_granularity: Constants.HOUR,
        time_window: Constants.DAYS7,
        start_date:moment().subtract(7, 'd').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        service_types:_service_types,
        router:"All",
        user_type:props.myRights?.userType,
        cp_names:_cpNames, //props.myRights?.userType===USER_TYPE.CUSTOMER?props.myRights?.cpName:_cpNames,
        granularityType:"minute"
    }

    useEffect(()=>{
        props.updatePeakInOutByRouterFilters(defaultFilters);
        let _filters=formatFiltersforAPI(defaultFilters) 
        if(defaultFilters.cp_names.trim().length !=0 && defaultFilters.service_types.trim().length !=0){
            props.getPeakInOutByRouter(_filters);  // get data
        }
    },[])

    const resetFilters=()=>{
        props.updatePeakInOutByRouterFilters(defaultFilters);
        let _filters=formatFiltersforAPI(defaultFilters) 
        props.getPeakInOutByRouter(_filters);
        childRef.current.handleClick();
    }
    const applyFilters=(filter)=>{  
        props.updatePeakInOutByRouterFilters(filter);
        let _filters=formatFiltersforAPI(filter) 
        props.getPeakInOutByRouter(_filters);
        childRef.current.handleClick();
    }
    
    const formatFiltersforAPI=(filters)=>{
        let obj={};

        if(filters?.time_granularity === Constants.MINUTE5){
            obj.time_granularity={
                minute:5
            }
            obj.granularityType="minute";
        }
        if(filters?.time_granularity === Constants.HOUR){
            obj.time_granularity={
                hour:1
            }
            obj.granularityType="hour";
        }
        if(filters?.time_granularity === Constants.MONTH){
            obj.time_granularity={
                month:1
            }
            obj.granularityType="month";
        }
        if(filters?.time_granularity === Constants.DAY){
            obj.time_granularity={
                day:1
            }
            obj.granularityType="day";
        }
        obj.time_window=filters?.time_window;
        obj.start_date=filters?.start_date;
        obj.end_date=filters?.end_date;
        obj.user_type=filters?.user_type;
        obj.cpNames=filters.cp_names;
        obj.ipxTypes=filters.service_types;
       // obj.router=filters.router;
        return obj
    }

 
    return (
        // <div className={styles.container}>
            <div className={styles.commonFilter}>
                {/* <Title /> */}
                <SelectedFilters selectedFiletrs={props.filters}/>
                <Overlay ref={childRef} >
                    <FilterContainer 
                        filter={props.filters} 
                        defaultFilters={JSON.parse(JSON.stringify(defaultFilters))}
                        resetFilters={resetFilters}
                        applyFilters={applyFilters}
                        cpNamesDrodownOptions={props.cpNamesDrodownOptions?props.cpNamesDrodownOptions:[]}
                        serviceTypesDrodownOptions={props.serviceTypesDrodownOptions?props.serviceTypesDrodownOptions:[]}/>
                </Overlay>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        filters: state.weatherMapDashboard.peakInOutByRouter.filters,
        // data: state.weatherMapDashboard.peakInOutByRouter.data,
        // isLoading: state.weatherMapDashboard.peakInOutByRouter.isLoading,
        cpNamesDrodownOptions:state.weatherMapDashboard.dropdownData.filters?.cpNames,
        serviceTypesDrodownOptions:state.weatherMapDashboard.dropdownData.filters?.serviceTypes,
        myRights:state.myRights.myRights,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPeakInOutByRouter: (filter) => {
            return dispatch(getPeakInOutByRouter(filter))
        },
        updatePeakInOutByRouterFilters: (filter) => {
            return dispatch(updatePeakInOutByRouterFilters(filter))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WeatherMapCommonFilter);


