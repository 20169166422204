import Service from "./Service";
import {connect} from "react-redux";
import { getServiceDetail } from "../../../redux/actions/newService/serviceDetail.actions";
import { getComments } from "../../../redux/actions/newService/comment.actions";
import { openCommentForm,closeCommentForm } from "../../../redux/actions/newService/comment.actions";
import { downloadFiles } from "../../../redux/actions/fileAttachment/fileAttachment.actions";
import { openServiceStatusForm } from "../../../redux/actions/newService/serviceStatus.actions";



const mapStateToProps = (state) => { 
  // console.log("demands",state.newService.serviceDetail)
    return {
      servicesDetail: state.newService.serviceDetail.serviceDetail,
      isLoading:state.newService.serviceDetail.loading,
      next: state.newService.comment?.next,
      comments: state.newService.comment.comments,
      isCommentFormOpen:state.newService.comment.isCommentFormOpen,
      isCommentsLoading:state.newService.comment.isLoading,
      myRights:state.myRights.myRights,
    }
  }

  const mapDispatchToProps = (dispatch)=>{
    return {        
        getServiceDetail: (requestId)=>{
            return dispatch(getServiceDetail(requestId))
        },       
        getComments:(requestId,next=null)=>{
          return dispatch(getComments(requestId,next))
        },
        openCommentForm : () => {
          return dispatch(openCommentForm())
      },
      closeCommentForm: () => {
          return dispatch(closeCommentForm())
      },
      downloadFile:(originalFileName,uniqueFileName)=>{
        return dispatch(downloadFiles(originalFileName,uniqueFileName))
      },
      openServiceStatusForm : () => {
        return dispatch(openServiceStatusForm())
      },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Service);


