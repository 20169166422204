import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import styles from "./addTicket.module.css";
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import * as Yup from "yup";
import IMAGES from "../../../../assets/images/images";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import { RadioContainer, RadioField,Wrapper,FailureSelectField,HRLine} from "../controllers/controllers"
import { 
  Incident_Issue_Type_Values, 
  IncidentServiceTypes, 
  Incident_Service_Type_Values,
  IVCIssueTypes,IPXIssueTypes,
  IVC_VOICE_FAILURE_OPTIONS,
  IPX_SIGNALLING_ISSUE_OPTIONS,
  IP_CONNECTIVITY_FAILURE_OPTIONS,
  IPX_SIGNALLING_ISSUE_OPTIONS_VALUES,
  IP_CONNECTIVITY_FAILURE_OPTIONS_VALUES,
  IVC_VOICE_FAILURE_OPTIONS_VALUES,
  IPX_IMPACTED_SUBSCRIBER_OPTIONS,
  IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES, 
  ADD_TICKET_ACTION
} from "../../../../utils/constants/incidentConstants";
import DiameterSignallingIssueMultipleForm from "../ipxRoaming/diameterSignallingIssueMultipleForm";
import DiameterSignallingIssueSingleForm from "../ipxRoaming/diameterSignallingIssueSingleForm";
import { getLabelFromValue } from "../../../../utils/arrayUtils"
import IpBGPSessionDownForm from "../ipConnectivity/ipBGPSessionDownForm";
import IPInterfaceDownForm from "../ipConnectivity/ipInterfaceDownForm";
import IPPacketDropHighLatencyHighUtilizationDownForm from "../ipConnectivity/ipPacketDrop_HighLatency_HighUtilizationDownForm";
import IVCCallFailure from "../ivcVoice/ivcCallFailure";
import IVCKPIDegradation from "../ivcVoice/ivcKPIDegradation";
import IVCTrafficProfile from "../ivcVoice/ivcTrafficProfile";
import IVCCLIDelivery from "../ivcVoice/ivcCLIDelivery";
import IVCDTMFFailure from "../ivcVoice/ivcDTMFFailure";
import IVCAudioQuality from "../ivcVoice/ivcAudioQuality";
import IVCITFSIssues from "../ivcVoice/ivcITFSIssues";
import IVCSIPTrunkConnectivityIssues from "../ivcVoice/ivcSIPTrunkConnectivityIssues";
import IVCRoamingVoiceIssues from "../ivcVoice/ivcRoamingVoiceIssues";
import SigtranSignallingIssueSingleForm from "../ipxRoaming/sigtranSignallingIssueSingleForm";
import SigtranSignallingIssueMultipleForm from "../ipxRoaming/sigtranSignallingIssueMultipleForm";
import IVCLNSIssues from "../ivcVoice/ivcLNSIssues";
import IVCUIFNIssues from "../ivcVoice/ivcUIFNIssues";
import GrxrSignallingIssueMultipleForm from "../ipxRoaming/grxSignallingIssueForm";

const AddTicket = ({incidentData=null,actionType,...props}) => {
  const [showIncidentForm, setShowIncidentForm] = useState(false);
  const [parentFormData,setParentFormData]=useState({});
  
  const closeForm = () =>{
    setShowIncidentForm(false);
  }

  let availedService =props?.myRights?.customerUserMetaInfo?.myServices?.map((service) => service?.serviceType);

  let initialValues ={}
  if(actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS){
    initialValues = {
      failure_type: incidentData?.failureRelatedType?.value,
      issue_type: incidentData?.incidentFaultType?.value,
      service_type: incidentData?.incidentServiceType?.value,
      impacted_subscriber: incidentData?.impactedSubscriber?.value
    }; 
  }else{
    initialValues = {
      service_type: availedService?.length > 1 ? "" : availedService[0],
      issue_type:'',
      failure_type:'',
      impacted_subscriber:''
    }; 
  }

  const validationSchema = Yup.object().shape({
    service_type: Yup.string(),
    issue_type:Yup.string(),
    failure_type:Yup.string(),
    impacted_subscriber:Yup.string()
  });

  const handleSubmit = (values) => {
    setParentFormData(values);
  }

  const renderIPXRoamingForm = (parentFormData, closeForm) => {

   switch (true) {
    case (parentFormData.failure_type === IPX_SIGNALLING_ISSUE_OPTIONS_VALUES.Sigtran && parentFormData.impacted_subscriber === IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Single):
      return (
        <SigtranSignallingIssueSingleForm parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData} />
      );
    case (parentFormData.failure_type === IPX_SIGNALLING_ISSUE_OPTIONS_VALUES.Sigtran && parentFormData.impacted_subscriber === IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Multiple):
      return (
        <SigtranSignallingIssueMultipleForm parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
      );
    case (parentFormData.failure_type === IPX_SIGNALLING_ISSUE_OPTIONS_VALUES.Diameter && parentFormData.impacted_subscriber === IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Single):
      return (
        <DiameterSignallingIssueSingleForm parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}  />
      );
    case (parentFormData.failure_type === IPX_SIGNALLING_ISSUE_OPTIONS_VALUES.Diameter && parentFormData.impacted_subscriber === IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Multiple):
      return (
        <DiameterSignallingIssueMultipleForm parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData} />
      );
    case (parentFormData.failure_type === IPX_SIGNALLING_ISSUE_OPTIONS_VALUES.GRX && parentFormData.impacted_subscriber === IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Multiple):
      return (
        <GrxrSignallingIssueMultipleForm parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData} />
      );
    default:
      return null;
  }
}

  const renderIPXIPConnectivityForm = (parentFormData,closeForm) => {
    switch (parentFormData?.failure_type) {
      case IP_CONNECTIVITY_FAILURE_OPTIONS_VALUES.BGP_Session_Down:
          return (
            <IpBGPSessionDownForm parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
        case IP_CONNECTIVITY_FAILURE_OPTIONS_VALUES.Interface_Down:
          return (
            <IPInterfaceDownForm parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
        case IP_CONNECTIVITY_FAILURE_OPTIONS_VALUES.Packet_Drop_High_Latency_High_Utilization_Down:
          return (
            <IPPacketDropHighLatencyHighUtilizationDownForm parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      default:
        return null;
    }
  }

  const renderIVCVoiceForm = (parentFormData,closeForm) => {
    switch (parentFormData?.failure_type) {
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.Call_Failure:
          return (
            <IVCCallFailure parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.KPI_Degradation:
        return (
          <IVCKPIDegradation parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
        );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.Traffic_Profile:
          return (
            <IVCTrafficProfile parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.CLI_Delivery:
            return (
              <IVCCLIDelivery parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
        );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.DTMF_Failure:
          return (
            <IVCDTMFFailure parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.Audio_Quality:
            return (
              <IVCAudioQuality parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.ITFS_Issues:
            return (
              <IVCITFSIssues parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.LNS_Issues:
            return (
              <IVCLNSIssues parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.UIFN_Issues:
            return (
              <IVCUIFNIssues parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.SIP_Trunk_Connectivity_Issues:
            return (
              <IVCSIPTrunkConnectivityIssues parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
      case IVC_VOICE_FAILURE_OPTIONS_VALUES.Roaming_Voice_Issues:
            return (
              <IVCRoamingVoiceIssues parentFormData={parentFormData} closeForm={closeForm} actionType={actionType} incidentData={incidentData}/>
          );
        
      default:
        return null;
    }
  }

 
  return (
    <div>
      {actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS?
      <button type="button" className={styles.open} onClick={() => {
        props.setIncidentPreviewMode(true);
        setShowIncidentForm(true);
        setParentFormData(initialValues);
      }}>
        View more details
        <img
          className={styles.arrowdown}
          src={IMAGES.chevronredright}
          alt="arrow"
        />
      </button>:
      <button onClick={() => {
        props.setIncidentPreviewMode(false);
        setShowIncidentForm(true);
        setParentFormData({});
      }}
      className={styles.addButton}>
        Raise New Incident
      </button>
      }
      {showIncidentForm ? (
        <Sidebar isOpen={showIncidentForm} sideNavStyle={styles.sideNavStyle} headerTxt={`${actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS?"Incident Details":props.isIncidentPreviewModeOn?"Summary - Incident Details":"Raise an Incident"}`} onClose={closeForm} scrollToTop={props.isIncidentPreviewModeOn}>
         <div className={styles.incidentSideNav}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, setFieldValue, values, handleChange, handleSubmit,handleBlur, resetForm }) => (

              <Form>     
                {availedService?.length > 1 ?          
                    <Wrapper>
                        <FailureSelectField
                          label={"Service Type"}
                          name="service_type"
                          placeholder={"Select a service type"}
                          options={IncidentServiceTypes}
                          isPreviewMode={props.isIncidentPreviewModeOn}
                          onChange={(e)=>{ 
                            handleChange(e);
                            setFieldValue("issue_type", "");
                            setFieldValue("failure_type", ""); 
                            setFieldValue("impacted_subscriber", ""); 
                            handleSubmit()
                          }}
                        />
                    </Wrapper>  
                    :<></>}
                  {
                    values.service_type ?                
                    <Wrapper>
                      <FailureSelectField
                        label={"Fault Type"}
                        name="issue_type"
                        placeholder={"Select fault type"}
                        options={values.service_type == Incident_Service_Type_Values.IPX_Service ? IPXIssueTypes : IVCIssueTypes}
                        isPreviewMode={props.isIncidentPreviewModeOn}
                        onChange={(e)=>{ 
                          handleChange(e);
                          setFieldValue("failure_type", ""); 
                          setFieldValue("impacted_subscriber", ""); 
                          handleSubmit()
                        }}
                      />
                    </Wrapper>
                    :<></>
                }  
                {
                 values.issue_type ?         
                    <Wrapper>
                        <FailureSelectField
                          label={"Service Fault Type"}
                          name="failure_type"
                          placeholder={"Select service fault type"}
                          options={
                            values.issue_type == Incident_Issue_Type_Values.IVC_Voice_Issues
                              ? IVC_VOICE_FAILURE_OPTIONS
                              : values.issue_type == Incident_Issue_Type_Values.IPX_Roaming_Issues
                              ? IPX_SIGNALLING_ISSUE_OPTIONS
                              : IP_CONNECTIVITY_FAILURE_OPTIONS
                          }  
                          isPreviewMode={props.isIncidentPreviewModeOn}
                          onChange={(e)=>{ 
                            handleChange(e);
                            setFieldValue("impacted_subscriber", "");
                            handleSubmit()
                          }}
                        />
                    </Wrapper> 
                     :<></>                     
                }
                {
                  values.issue_type == Incident_Issue_Type_Values.IPX_Roaming_Issues && values.failure_type ?
                    <Wrapper>
                        <FailureSelectField
                          label={"Impacted Subscriber"}
                          name="impacted_subscriber"
                          placeholder={"Select impacted subscriber"}
                          options={IPX_IMPACTED_SUBSCRIBER_OPTIONS}
                          isPreviewMode={props.isIncidentPreviewModeOn}
                          onChange={(e)=>{ 
                            handleChange(e);
                            handleSubmit()
                          }}
                        />
                    </Wrapper> :<></>
                } 
                {
                   values.issue_type?
                   <Wrapper>
                     <HRLine/>
                  </Wrapper>:null
                }
              </Form>
            )}
          </Formik>
          {
            parentFormData?.service_type == Incident_Service_Type_Values.IPX_Service &&
              parentFormData.issue_type==Incident_Issue_Type_Values.IPX_Roaming_Issues &&
              parentFormData.impacted_subscriber ==   IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Single || 
              parentFormData.impacted_subscriber ==   IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Multiple ?
                renderIPXRoamingForm(parentFormData,closeForm) :
            parentFormData?.service_type == Incident_Service_Type_Values.IPX_Service &&
                parentFormData.issue_type==Incident_Issue_Type_Values.IPX_IP_Connectivity ?
                  renderIPXIPConnectivityForm(parentFormData,closeForm) :
            parentFormData?.service_type == Incident_Service_Type_Values.IVC_Service &&
                  parentFormData.issue_type==Incident_Issue_Type_Values.IVC_IP_Connectivity ?
                    renderIPXIPConnectivityForm(parentFormData,closeForm) :
            parentFormData?.service_type == Incident_Service_Type_Values.IVC_Service &&
                    parentFormData.issue_type==Incident_Issue_Type_Values.IVC_Voice_Issues ?
                      renderIVCVoiceForm(parentFormData,closeForm) :<></>
          }
          </div>
        </Sidebar>
      ) : null}
    </div>
  );
};

export default AddTicket;