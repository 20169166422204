import { Link, useParams, useSearchParams } from "react-router-dom";
import IMAGES from "../../../../../../assets/images/images";
import {
  ChildContainer,
  Container,
} from "../../../../../../components/Container/Containers";
import { FormFieldLabel as Label, FormErrorLabel as ErrorLabel, FormFieldLabel } from "../../../../../../components/label/Labels";
import { Search } from "../../../../../../components/search/search";
import styles from "./accountRequestListViewer.module.css";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { formatSecondsToDate } from "../../../../../../utils/dateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../../../components/loader/Loader";
import { EmptyContainer } from "../../../../../dashboard/graphs/component/component";
import { useNavigate } from "react-router-dom";
import DropDown from "../../../../../../components/dropDown/DropDown";
import { useField } from "formik";
import {formatDate, getLabelFromValue} from "../../../../../../utils/arrayUtils";

const AccountRequestList = ({data}) => {
    const navigate = useNavigate();
    const gotoDetail = (requestId) => {
      navigate(`/accountAccessRequest/${requestId}`);
    }

    return (
  <> 
       <div className={styles.container}>
                
         <div className={styles.head}>
              <div className={styles.subhead}>
                    <div className={styles.orgname} onClick={()=>gotoDetail(data.id)}>{data?.requestId}</div>
                    <div className={`${styles.status} ${styles[data?.requestStatus?.value]}`}>{data?.requestStatus?.label}</div>
              </div>
              {/* <div> <img onClick={()=>gotoDetail(data.id)} className={styles.gotologo} src={IMAGES.chevronredright} alt="" /></div> */}
         </div>
         <div className={styles.listContent}>
         <div className={styles.list}>
                <div className={styles.label}>Organization Legal Name</div>
                <div className={styles.value}>{data?.displayName}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Service Type</div>
                <div className={styles.value}>{data?.serviceType?.label}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Request Assigned To</div>
                <div className={styles.value}>{data?.requestAssignedTo?.label}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Requested On</div>
                <div className={styles.value}>{formatDate(data?.createdAt)}</div>
             </div>
             </div>            
       </div>
  </>
    );
  };
  

const AccountRequestListViewer = (props) => {
    const FieldWrapper = ({ children }) => {
        return <div className={styles.fieldWrapper}>{children}</div>;
      };

    const SelectField = ({ title, options, ...props }) => {
        // let _options = options.map((item) => ({
        //   value: item.value,
        //   label: item.label,
        // }));
       // const [field, meta, helpers] = useField(props);
    
    
        //const hasError = meta.touched && meta.error ? true : false;
        const obj = {
            width: "249px",
        }
        return (

          <FieldWrapper>
            {/* <div>
              <Label text={title} style={styles.labelcompLevel}/>
            </div> */}
            <DropDown
              //options={_options}
            //   customStyles={props.customStyles}
            //   onChange={({ label, value }) => {
            //     helpers.setValue(value);
            //   }}
            //   value={
            //     _options && field.value
            //       ? _options.find((option) => option.value === field.value)
            //       : ""
            //   }
            //   hasError={hasError}
            customStyles ={obj}
            //   isDisabled={props.isDisabled}
            />
            {/* {hasError ? <ErrorLabel text={meta.error} /> : null} */}
          </FieldWrapper>
        );
      };

      useEffect(() => {
       props.getAccountAccessRequestList();
      }, []);

      
      const fetchData = () => {
        if (props?.next) {
          props.getAccountAccessRequestList(props?.next);
        }
      };

  return (
<ChildContainer>
  {props?.requestList?.length>0&&(
    <div className={styles.headnote}><img className={styles.infocircle}src={IMAGES.infoCircle}/>Extension Access description</div>
  )}
    {/* <div className={styles.searchhead}>
      <div className={styles.search}>
        <Search 
          //  filterData={filterData}
          //  getSearchKeyValue={getSearchKeyValue}
          //  placeholder="Search"
          //  onKeyDown={onKeyDownHandler}
        />
      </div>
      <div className={styles.statussearch}>
        <SelectField
        title="Status" 
        />
      </div>
    </div> */}
    {props?.isLoading && props?.requestList?.length != 0 && <Loader />}
     {props?.isLoading && props?.requestList?.length == 0 ? (
        <Loader/>
      ) : props?.requestList?.length == 0 ? (
        <div className={styles.Emptydiv}>No Records Found</div> 
      ) : (
        <InfiniteScroll
          dataLength={props?.requestList?.length} //This is important field to render the next data
          next={() => {
            fetchData();
          }}
          hasMore={props?.next}
          loader={<Loader />}
        >
          {props?.requestList?.map((item) => (
             <AccountRequestList  data={item}/>
          ))}
        </InfiniteScroll>
      )}
</ChildContainer>
  );
};

export default AccountRequestListViewer;
