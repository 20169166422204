/*New VF User*/

import { Field, Form, Formik ,useField , ErrorMessage} from "formik";
import React, { useId, useState } from "react";
import * as Yup from "yup";
import Asterisk from "../../../../components/asterisk/Asterisk";
import { BackButton, Submit } from "../../../../components/button/buttons";
import { TextInput } from "../../../../components/input/inputs";
import IMAGES from "../../../../assets/images/images";  
import { FormFieldLabel as Label ,FormErrorLabel as ErrorLabel} from "../../../../components/label/Labels";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import styles from "./NewVFUser.module.css";
// import { Alert } from "../../../../components/alert/alerts";
import { FUNCTIONAL_ROLES_CONSTANTS, USER_TYPE } from "../../../../utils/constants/userConstants";
import { capitalizeWords } from "../../../../utils/utils";
import DropDown from "../../../../components/dropDown/DropDown";
const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? styles.inputError
    : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
        <Asterisk />
      </div>
      <TextInput  {...field}  {...props} placeholder={placeholder}   style={`${inputStyle} ${styles.textfield}`}  onChange={props.onChange}/>
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const PhoneNumberTextField = ({ label, placeholder, ...props }) => {
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
      </div>
      <TextInput {...props} placeholder={placeholder} />
    </FieldWrapper>
  );
};

const CheckBox = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};
const RadioField = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="radio"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};

const SelectField = ({ title, options, ...props }) => {
  let _options = options?.map((item) => ({
    value: item?.value,
    label: item?.label,
  }));
  const [field, meta, helpers] = useField(props);

  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
        <Label text={title}  style={`${styles.labelcompLevel} ${props.additionalStyle}`} /><Asterisk />
        <span className={styles.circlered}>
          <span className={styles.tooltip}>
            {props.name === "ServiceImpacted" ? <Asterisk /> : <></>}
            {/* <Tooltip text={props.tooltiptxt} /> */}
          </span>
        </span>
      </div>
      <DropDown
        options={_options}
        customStyles={props.customStyles}
        onChange={({ label, value }) => {
          helpers.setValue(value);
          props.onChange(value);
        }}
        value={
          _options && field.value
            ? _options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const ExpandableArea = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  if (!expanded) {
    return (
      <div className={styles.expanded}>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(true)}
        >
          <span className={styles.view}>View All</span>
          <img src={IMAGES.arrowdown} width={24} height={24} alt="down arrow" />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.expanded}>
       {children}
      <div>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(false)}
        >
          <span className={styles.view}>View Less</span>
          <img src={IMAGES.arrowup} width={24} height={24} alt="up arrow" />
        </button>
      </div>

     
    </div>
  );
};
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").max(100,"Email must be at most 100 characters").required("Required"),
  fullName: Yup.string()
    .min(2, "Too Short!")
    .max(100,"Name must be at most 100 characters")
    .required("Required"),
    phoneNumber: Yup.string().matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number").nullable(true),
    functionalRoles:Yup.string().required("Kindly choose one role.") //array().min(1,"Kindly choose at least one role.").max(1,"Please select only one role.")
});

const NewVFUser = (props) => {
  const {functionalRoles}=props;
  const [showAddUser, setShowAddUser] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  let [isAlert, setIsAlert] = useState(false);
  let [isEditPermission, setIsEditPermission] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });

  let initialValues = {
    email: props?.data?.email || "",
    fullName: props?.data?.displayName || "",
    phoneNumber: null,
    assignModule: "manual",
    userType: "internal",
    cpName: null, 
    functionalRoles: props?.data?.functionalRoles && props?.data?.functionalRoles.length > 0? props?.data?.functionalRoles.toString(): "",
  };
const functionalRolesData = functionalRoles?.map((role) => ({
  value: role?.id,
  label: role?.name,
}));

  const close = () => setShowAddUser(false);

  const editPermission =()=>{
    setIsEditPermission(!isEditPermission);
  }

  const createUser=(obj)=>{
    props.createUser(obj).then((result) => {
      if (result.status) {
        setIsSubmit(false);
        props.openAlertPopup({
          message: "New user added successfully!",
          image: IMAGES.success
        });
       
        close();
        setIsAlert(true);
        let searchKey = "";
        let type=USER_TYPE.INTERNAL;
        let custID="";
        let next=null;
        props.getUser(type,custID,searchKey,next);
      } else {
        setIsSubmit(false);
        let error_msg = result?.error;
        close();
        props.openAlertPopup({
          message: error_msg,
          image: IMAGES.error
        });
      }
    })
      .catch((error) => {
        setIsSubmit(false);
        close();
        props.openAlertPopup({
          message: "Something went wrong.",
          image: IMAGES.error
        });
      });
  }

  const updateUser=(obj,values)=>{
 
    let objs =  { 
      "functionalRoles":[values?.functionalRoles],
       userType:props?.data?.userType
      }
      setIsSubmit(true);
      props.updateUserPermissions(objs, props?.data?.uid)
        .then((result) => {
          setIsSubmit(false);
          setIsEditPermission(!isEditPermission);
          if (result.status) {
            props.openAlertPopup({
              message: "User permissions updated successfully!",
              image: IMAGES.success
            });
            props.getUser(USER_TYPE.INTERNAL,"","","");
            close();
          } else {
            let error_msg = result?.error;
            props.openAlertPopup({
              message: error_msg,
              image: IMAGES.error
            });
            close();
          }
        })
        .catch((error) => {
          setIsSubmit(false);
          setIsEditPermission(!isEditPermission);
          props.openAlertPopup({
            message: "Something went wrong.",
            image: IMAGES.error
          });
          close();
        });
  }


  return (
    <div>
      {props.source=='edit'  ?(
         <div
         onClick={() => setShowAddUser(true)}
         className={styles.addButtons}
       >
         <div className={styles.btndiv}>{FUNCTIONAL_ROLES_CONSTANTS[props?.data?.functionalRoles[0]]?.label}</div>
         <div className={styles.btndiv}> <img className={styles.editrole} src={IMAGES.editrole} /></div>
         </div>

     
      ) : (
        <button
        onClick={() => setShowAddUser(true)}
        className={styles.addButton}
      >
        Add New Vodafone User

      </button>
      )}
      {showAddUser && (
        <Sidebar
          isOpen={showAddUser}
          headerTxt="Add New Vodafone User"
          onClose={close}
        >
          <Formik
          
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={async (values) => {
              let obj = JSON.parse(JSON.stringify(values));
              delete obj.assignModule; 
              obj.functionalRoles=[values.functionalRoles];
              setIsSubmit(true);
              if(props.source=='new'){
                createUser(obj);
              }else{
                updateUser(obj,values);
              }
            }
          }

          >
            {({
              isValid,
              dirty,
              setFieldValue,
              values,
              handleChange,
              handleBlur,
            }) => (
              <Form className={styles.form}>
                <fieldset>
                  <TextField
                    label="Email Address"
                    name="email"
                    type="text"
                    placeholder="Input text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={props.source=='edit'}
                  />
                  <TextField
                    label="Full Name"
                    name="fullName"
                    type="text"
                    placeholder="Input text"
                  //  onChange={handleChange}
                    onBlur={handleBlur}
                    onChange={(e)=>{
                      setFieldValue("fullName",capitalizeWords(e.target.value));
                      }}
                    disabled={props.source=='edit'}
                  />
                   <SelectField
                                title={"User Role"}
                                name="functionalRoles"   
                                options={functionalRolesData}
                                customStyles={styles.obj}
                                placeholder="Enter name"
                               
                               onChange={(value)=>{
                              //  setFieldValue("primaryContactName", value)
                               }}
                                />
                <div className={styles.discription}>
                   <div className={styles.userrole}>User Roles</div>
                   <div class={styles.gridcontainer}>
                   {props.functionalRoles?.slice(0, 4)?.map((role) => (                                 
                       <div class={styles.griditem}>                       
                            <div className={styles.rolename}>{role.name}</div>
                            <div className={styles.roleinfo}>{role.description}</div>                                               
                       </div>                      
                 ))}
                   </div> 
                <ExpandableArea>
                <div style={{ width: "100%" }}>
           
                   <div class={styles.gridcontainer}>
                   {props.functionalRoles?.slice(4)?.map((role) => (                     
                           <div class={styles.griditem}>                       
                            <div className={styles.rolename}>{role.name}</div>
                            <div className={styles.roleinfo}>{role.description}</div>                                                
                       </div>                     
                 ))}
                   </div> 
                   </div>
                </ExpandableArea>
                </div>
                  {/*<PhoneNumberTextField
                    label="Mobile number"
                    name="phoneNumber"
                    type="text"
                    placeholder="XXXXX XXXXX"
                    onChange={handleChange}
                    onBlur={handleBlur}
            />*/}
            
                {/* <h2 className={styles.assign}>Assign Role</h2> */}
                 {/* <div>
                    <div className={styles.radioGroup}>
                      <div className={styles.radioField}>
                        <Field
                          id="manual"
                          type="radio"
                          name="assignModule"
                          value="manual"
                          className={styles.radioInput}
                        />
                        <label htmlFor="manual" className={styles.radioLabel}>
                          Select Manually
                        </label>
                      </div>

                      <div className={styles.radioField}>
                        <Field
                          id="admin"
                          className={styles.radioInput}
                          type="radio"
                          name="assignModule"
                          value="admin"
                          onClick={(val) => {
                            setFieldValue(
                              "functionalRoles",
                              functionalRoles.map((role) => role.id)
                            );
                          }}
                        />
                        <label htmlFor="admin" className={styles.radioLabel}>
                          Make an admin
                          <br /> (gives access to all modules)
                        </label>
                      </div>
                    </div>
                  </div>*/}

                  {/* <fieldset
                    disabled={values.assignModule === "admin"}
                    className={styles.checkboxaligngrid}
                  >
                    {functionalRoles?.map((role) => (
                      <RadioField name="functionalRoles" value={role.id} label={role.name}/>
                    ))}
                  </fieldset>
                  <ErrorMessage name ="functionalRoles">
                    {(msg)=><ErrorLabel text={msg} />}
                  </ErrorMessage> */}
                </fieldset>
                 

                <div>
                  <hr className={styles.separator} />
                  <ButtonWrapper>
                    <BackButton
                      className={styles.backButton}
                      style={styles.submit}
                      title={"Back"}
                      closePopup={close}
                      // onClose={close}
                    />
                    <Submit
                      title={props.source=="edit"?"Save Change": "Submit"}
                      isSubmitting={isSubmit}
                     disabled={!isValid || !dirty}
                    />
                  </ButtonWrapper>
                </div>
              </Form>
            )}
          </Formik>
        </Sidebar>
      )}
      {/* {isAlert && (
                  <div
                    style={{
                      zIndex: 20,
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                    }}
                  >
                    <Alert
                      image={message.image}
                      message={message.message}
                      onClose={setIsAlert}
                    />
                  </div>
                )} */}
    </div>
  );
};

export default NewVFUser;
