import { Sidebar } from "../../../../components/sidebar/Sidebar";
import styles from "./NewCustomer.module.css";

import React, { useEffect, useId, useState } from "react";
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import { FormTextArea, TextInput } from "../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../components/button/buttons";
import FileAttachment from "../../../../components/fileAttachment/fileAttachment.container";
import { DetailContainer } from "../../../../components/comment/comment";
import Asterisk from "../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../assets/images/images";
import * as DOMPurify from "dompurify";
import Tooltip from "../../../../components/toolTip/toolTip";
import DropDown from "../../../../components/dropDown/DropDown";
import { SearchField } from "../../../../components/search/search";
import CustomerViewer from "../list/CustomerViewer";
import { useNavigate, Link } from "react-router-dom";
import { Incident_Service_Type_Values } from "../../../../utils/constants/incidentConstants";
import { formatSecondsToDate } from "../../../../utils/dateTime";

const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
export const Wrapper = ({ children }) => {
  return <div className={styles.wrapperContainer}>{children}</div>;
};

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldWrapper}>{children}</div>;
};
const CustomerNotFound = () => {
  return (
    <div className={styles.customerNotFound}>
      <div className={styles.customerNottxt}> No Customer Found</div>
      <div className={styles.customerNotFoundtxt}>
        Would you like to onboard a new customer?
      </div>
    </div>
  );
};
const CustomerList = (props) => {
  const navigate = useNavigate();

  function keyValueExistsInArray(arr, keyToCheck, valueToCheck) {
    for (const element of arr) {
      if (element[keyToCheck] === valueToCheck) {
        return true;
      }
      if (Array.isArray(element.accountManagers)) {
        if (keyValueExistsInArray(element.accountManagers, keyToCheck, valueToCheck)) {
          return true;
        }
      }
    }
    return false;
  }

  const gotoDetail = (item) => {
    const result =keyValueExistsInArray(item.serviceSpecificDetails,"email",props.myEmail)
    if(result){
      navigate(`/user-management?customerId=${encodeURIComponent(item.uniqueCustomerRefId)}`)
    }else{
      navigate(`/AccessApprovalDetail/${item.id}`)
    }
    //{`/user-management?customerId=${encodeURIComponent(item.uniqueCustomerRefId)}`}
  };

  return (
    <div>
      <div className={styles.containerheader}>Existing Onboarded Customers</div>
      <div className={styles.headnote}>
        <img className={styles.infocircle} src={IMAGES.infoCircle} />
        Select the customer for whom you would like to add a service.
      </div>
      {props.list?.map((item, key) => (
        <div className={styles.containers} key={key}>
          <div className={styles.headsearch}>
            <div className={styles.orgnamehead}>{item?.displayName}</div>
            <div className={styles.subhead}>
              <img  onClick={()=>gotoDetail(item)} src={IMAGES.chevronredright}/>
              {/* <Link
                to={`/AccessApprovalDetail
               `}
              >
                <img src={IMAGES.chevronredright} alt="" />
              </Link> */}
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Service Type</div>
                <div className={styles.sub}>
                  {item.serviceSpecificDetails
                    ?.map((detail) => detail.serviceType.label)
                    .join(" & ")}
                </div>
              </div>
            </div>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Organisation Legal Name</div>
                <div className={styles.sub}>{item?.displayName}</div>
              </div>
            </div>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Onboarded On</div>
                <div className={styles.sub}>
                  {formatSecondsToDate(item?.createdAt)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Search = ({ label, placeholder, onChange, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? { ...styles.inputError, width: "250px" }
    : { width: "250px" };

  return (
    <FieldWrapper>
      <div>
        <Label text={label} style={styles.labelcompLevel} /> <Asterisk />
      </div>
      <SearchField
        {...field}
        {...props}
        placeholder={placeholder}
        style={styles.additionalStyle}
        onChange={(e) => {
          helpers.setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const CheckBox = ({ name, value, label, onChange, checked }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
        <Label text={label} optional={false} style={styles.labelcompLevel} />
      </div>
      <FileAttachment
        name={props.name}
        value={value}
        setFieldValue={setFieldValue}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError ? styles.inputError : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} style={styles.labelcompLevel} />
        <Asterisk />
        {props.toltip ? (
          <span className={styles.toltip}>
            <Tooltip text={"TADIG ID"} />
          </span>
        ) : (
          <></>
        )}
      </div>
      <TextInput
        {...field}
        {...props}
        placeholder={placeholder}
        style={inputStyle}
        //   onChange={props.onChange}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const SelectField = ({ title, options, ...props }) => {
  let _options = options.map((item) => ({
    value: item.value,
    label: item.label,
  }));
  const [field, meta, helpers] = useField(props);

  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
        <Label
          text={title}
          style={`${styles.labelcompLevel} ${props.additionalStyle}`}
        />
        <span className={styles.circlered}>
          <span className={styles.tooltip}>
            <Asterisk /> 
          </span>
        </span>
      </div>
      <DropDown
        options={_options}
        customStyles={props.customStyles}
        onChange={({ label, value }) => {
          props.onChange(value);
          helpers.setValue(value);
        }}
        value={
          _options && field.value
            ? _options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
        <Label text={label} optional={optional} style={styles.labelcompLevel} />
       </div>
      <FormTextArea {...field} {...props} style={styles.additionalStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const NewCustomer = (props) => {
  const [show, setShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [continueClicked, setContinueClicked] = useState(false);
  let failuerTypeDropdownDropObj = {
    width: "300px",
  };

  const YesContinue = () => {
    setContinueClicked(true);
  };

  const filteredList = props?.list?.filter((item) => {
    return item?.displayName?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const [isSameAsAboveChecked, setIsSameAsAboveChecked] = useState(false);
  const [isIPXServicesChecked, setIsIPXServicesChecked] = useState(false);
  const [
    isInternationalVoiceServicesChecked,
    setIsInternationalVoiceServicesChecked,
  ] = useState(false);

  const accountManagerList = props.accountManagerList;

  const accountManager = accountManagerList.map((item) => ({
    value: item.email,
    label: item.displayName,
  }));

  const accountManagerEmailMap = accountManagerList.reduce((acc, item) => {
    acc[item.displayName] = item.email;
    return acc;
  }, {});

  const handleCheckboxChange = (event, setFieldValue, values) => {
    const { name, value, checked } = event.target;
    let newValues = Array.isArray(values[name]) ? [...values[name]] : [];

    if (name !== "sameasabove") {
      if (checked) {
        newValues.push(value);
      } else {
        newValues = newValues.filter((v) => v !== value);
      }
    }

    setFieldValue(name, newValues);

    switch (value) {
      case Incident_Service_Type_Values.IPX_Service:
        setIsIPXServicesChecked(checked);
        break;
      case Incident_Service_Type_Values.IVC_Service:
        setIsInternationalVoiceServicesChecked(checked);
        break;
      case "sameasabove":
        setIsSameAsAboveChecked(checked);
        if (checked) {
          setFieldValue("primaryContactNameivc", values?.primaryContactName);
          setFieldValue("primaryEmailAddressivc", values?.primaryEmailAddress);
          setFieldValue("accountManagerivc", values?.accountManager);
          setFieldValue("accountManagerEmailivc", values?.accountManagerEmail);
         } else {
          setFieldValue("primaryContactNameivc", "");
          setFieldValue("primaryEmailAddressivc", "");
          setFieldValue("accountManagerivc", "");
          setFieldValue("accountManagerEmailivc", "");
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    props.getAccountManagerList();
  }, []);

  useEffect(() => {
    if (searchQuery?.length === 0 || filteredList?.length > 0) {
      setContinueClicked(false);
      setIsIPXServicesChecked(false);
      setIsSameAsAboveChecked(false);
      setIsInternationalVoiceServicesChecked(false);
    }
    if (!isIPXServicesChecked || !isInternationalVoiceServicesChecked) {
      setIsSameAsAboveChecked(false);
    }
  }, [searchQuery, filteredList, props]);

  const close = () => {
    setShow(false);
    setSearchQuery("");
    setContinueClicked(false);
    setIsIPXServicesChecked(false);
    setIsInternationalVoiceServicesChecked(false);
  };

  const serviceType =
    (isIPXServicesChecked && !isInternationalVoiceServicesChecked) ||
    (isIPXServicesChecked && isInternationalVoiceServicesChecked);
  const serviceTypeivc =
    (!isIPXServicesChecked && isInternationalVoiceServicesChecked) ||
    (isIPXServicesChecked && isInternationalVoiceServicesChecked);

  const getValidationSchema = (
    isIPXServicesChecked,
    isInternationalVoiceServicesChecked
  ) => {
    const baseSchema = {
      organisationLegalName: Yup.string().required("Required"),
      // additionalInformation: Yup.string().required("Required"),
      files: Yup.array().max(3),
    };

    if (isIPXServicesChecked) {
      baseSchema.tadigCode = Yup.string().required("Required");
      baseSchema.primaryContactName = Yup.string().required("Required");
      baseSchema.primaryEmailAddress = Yup.string().required("Required");
      baseSchema.accountManager = Yup.string().required("Required");
      baseSchema.accountManagerEmail = Yup.string().required("Required");
    }

    if (isInternationalVoiceServicesChecked) {
      baseSchema.primaryContactNameivc = Yup.string().required("Required");
      baseSchema.primaryEmailAddressivc = Yup.string().required("Required");
      baseSchema.accountManagerivc = Yup.string().required("Required");
      baseSchema.accountManagerEmailivc = Yup.string().required("Required");
    }

    return Yup.object(baseSchema);
  };

  const getAccountManagerName = (email) => {
    let accountManagerName = accountManager.filter(
      (item) => item.value == email
    );
    return accountManagerName[0].label;
  };

  return (
    <>
      <button onClick={() => setShow(true)} className={styles.continue}>
        Add New Customer
      </button>
      {show && (
        <Sidebar
          isOpen={show}
          headerTxt="Add New Customer"
          onClose={close}
          sideNavStyle={styles.sideNavStyle}
        >
          <div>
            <Formik
              validationSchema={getValidationSchema(
                isIPXServicesChecked,
                isInternationalVoiceServicesChecked
              )}
              initialValues={{
                organisationLegalName: "",
                tadigCode: "",
                primaryContactName: "",
                primaryEmailAddress: "",
                accountManager: "",
                accountManagerEmail: "",
                additionalInformation: "",
                internationalVoiceServices: [],
                ipxServices: [],
                primaryContactNameivc: "",
                primaryEmailAddressivc: "",
                accountManagerivc: "",
                accountManagerEmailivc: "",
                additionalInformationivc: "",
                files: [],
              }}
              onSubmit={async (values, { resetForm }) => {
                setIsSubmit(true);
                let obj = {
                  displayName: values.organisationLegalName,
                  uniqueCustomerRefId: null,
                  requestType: "new_onboarding",
                  requestDetails: [],
                  additionalInfoText: values.additionalInformation,
                  additionalInfoAttachments: values.files,
                };

                if (
                  isIPXServicesChecked ||
                  isInternationalVoiceServicesChecked
                ) {
                  if (isInternationalVoiceServicesChecked) {
                    obj.requestDetails.push({
                      cpName: null,
                      serviceType: values.internationalVoiceServices.toString(),
                      customerPrimaryContact: [
                        {
                          name: values.primaryContactNameivc,
                          email: values.primaryEmailAddressivc,
                        },
                      ],
                      customerAccountManagers: [
                        {
                          name: getAccountManagerName(values.accountManagerivc),
                          email: values.accountManagerEmailivc,
                        },
                      ],
                    });
                  }

                  if (isIPXServicesChecked) {
                    obj.requestDetails.push({
                      cpName: values.tadigCode,
                      serviceType: values.ipxServices.toString(),
                      customerPrimaryContact: [
                        {
                          name: values.primaryContactName,
                          email: values.primaryEmailAddress,
                        },
                      ],
                      customerAccountManagers: [
                        {
                          name: getAccountManagerName(values.accountManager),
                          email: values.accountManagerEmail,
                        },
                      ],
                    });
                  }
                }
                props
                  .submitRequest(obj)
                  .then((result) => {
                    if (result.status) {
                      props.openAlertPopup({
                        message: `Customer onboarding request with id: ${result?.res?.data?.requestId} has been sent to the Commercial Manager!`,
                        image: IMAGES.success,
                      });
                      setIsSubmit(false);
                      resetForm();
                      close();
                      // props.getServiceList();
                    } else {
                      let error_msg =
                        result?.error?.message ?? "Something went wrong.";

                      props.openAlertPopup({
                        message: error_msg,
                        image: IMAGES.error,
                        isFormOpen: true,
                      });
                      setIsSubmit(false);
                      close();
                    }
                  })
                  .catch((error) => {
                    props.openAlertPopup({
                      message: "Something went wrong.",
                      image: IMAGES.error,
                      isFormOpen: true,
                    });
                    setIsSubmit(false);
                    close();
                  });
              }}
            >
              {({
                isValid,
                dirty,
                values,
                setFieldValue,
                resetForm,
                handleChange,
              }) => (
                <>
                  <Form className={styles.form} id="Add_Customer">
                    <Search
                      label="Organisation Legal Name"
                      placeholder="Search customer by typing their organization legal name"
                      name="organisationLegalName"
                      customStyles={failuerTypeDropdownDropObj}
                      onChange={setSearchQuery}
                    />
                    {!searchQuery && (
                      
                       
                      <div className={styles.initalinfo}>
                        <div className={styles.initalinfohead}>
                          How to onboard a new customer?
                        </div>
                        <div className={styles.containerinfo}>
                        <div className={styles.dflexs}>
                          <div className={styles.redflex}>
                             <div className={styles.redin}></div>
                             <div className={styles.blackin}></div>
                          </div>
                          <div className={styles.black}></div>
                          <div className={styles.black}></div>
                          <div className={styles.black}></div>
                          <div className={styles.black}></div>
                          <div className={styles.black}></div>
                          <div className={styles.black}></div>
                          <div className={styles.redflex}>
                             <div className={styles.redin}></div>
                             <div className={styles.blackin}></div>
                          </div>
                        </div>
                        <div className={styles.dflex}>
                              <div className={styles.initalinfotext}>
                                <span className={styles.userinfosearch}> <img  className={styles.userinfosearchimg} src={IMAGES.userinfosearch} /> </span>
                          1. Search For Existing Customer
                          <div className={styles.info}>
                            Before onboarding a new customer, search by their
                            organization’s legal name.
                          </div>
                             </div>
                               <div className={styles.initalinfotext}>
                               <span className={styles.userinfosearch}> <img  className={styles.userinfosearchimg} src={IMAGES.userinfosearch} /> </span>
                         
                          2. Update or Create Account
                          <li className={styles.info}>
                            For existing customer, request access to update
                            their information.
                          </li>
                          <li className={styles.info}>
                            If customer does not exist, create a new customer
                            account.
                          </li>
                              </div>
                      </div>
                      </div>
                  </div>
                      // <div>
                      //   <img
                      //     className={styles.onboardinfoimg}
                      //     src={IMAGES.onboardinfo}
                      //     alt=""
                      //   />
                      // </div>
                    )}
                    {searchQuery && (
                      <>
                        {filteredList?.length > 0 ? (
                          <div className={styles.containerlist}>
                            <CustomerList list={filteredList} myEmail={props.myRights.email}/>
                          </div>
                        ) : (
                          !continueClicked && <CustomerNotFound />
                        )}
                        {filteredList?.length <= 0 && continueClicked && (
                          <>
                            <div className={styles.chackboxtxt}>
                              Choose one or more service type{" "}
                              <Tooltip
                                text={"Choose one or more service type"}
                              />
                              <div></div>
                            </div>
                            <div className={styles.chackboxmain}>
                              <div>
                                <CheckBox
                                  name="ipxServices"
                                  value={
                                    Incident_Service_Type_Values.IPX_Service
                                  }
                                  label="IPX Services"
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                  id="IPX_Services"
                                  checked={values.ipxServices.includes(
                                    Incident_Service_Type_Values.IPX_Service
                                  )}
                                />
                              </div>
                              <div>
                                <CheckBox
                                  id="International_Voice_Services"
                                  name="internationalVoiceServices"
                                  value={
                                    Incident_Service_Type_Values.IVC_Service
                                  }
                                  label="International Voice Services"
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                  checked={values.internationalVoiceServices.includes(
                                    Incident_Service_Type_Values.IVC_Service
                                  )}
                                />
                              </div>
                            </div>
                            {serviceType && (
                              <>
                                {isIPXServicesChecked &&
                                  isInternationalVoiceServicesChecked && (
                                    <div className={styles.ipxform}>
                                      IPX Services
                                    </div>
                                  )}

                                <TextField
                                  label="TADIG Code"
                                  name="tadigCode"
                                  type="text"
                                  toltip="true"
                                  placeholder="Enter ID"
                                />

                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <TextField
                                      label="Customer Primary Contact Name"
                                      name="primaryContactName"
                                      type="text"
                                      placeholder="Enter name"
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Customer Primary Email Address"
                                      name="primaryEmailAddress"
                                      type="text"
                                      placeholder="Enter email address"
                                    />
                                  </div>
                                </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <SelectField
                                      title={"Account Manager"}
                                      name="accountManager"
                                      onChange={(value) => {
                                        setFieldValue(
                                          "accountManagerEmail",
                                          value
                                        );
                                        //handleChange();
                                      }}
                                      options={accountManager}
                                      customStyles={styles.obj}
                                      placeholder="Enter name"
                                      additionalStyle={styles.additionalStyle}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    {/* <SelectField
                                        title={"Account Manager Email Address"}
                                        name="accountManagerEmail"                                        
                                        options={accountManager.map((item) => ({
                                          value: accountManagerEmailMap[item.value],
                                          label: accountManagerEmailMap[item.value],
                                        }))}
                                        customStyles={styles.obj}
                                        placeholder="No Selection"
                                      /> */}
                                    <TextField
                                      label="Account Manager Email Address"
                                      name="accountManagerEmail"
                                      type="text"
                                      value={values.accountManagerEmail}
                                      placeholder=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            {serviceTypeivc && (
                              <>
                                {isIPXServicesChecked &&
                                  isInternationalVoiceServicesChecked && (
                                    <>
                                      <div className={styles.ipxform}>
                                        International Voice Services
                                      </div>
                                      <CheckBox
                                        name="sameasabove"
                                        value="sameasabove"
                                        label="Same as above"
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            e,
                                            setFieldValue,
                                            values
                                          )
                                        }
                                        id="sameasabove"
                                        checked={isSameAsAboveChecked}
                                      />
                                    </>
                                  )}
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <TextField
                                      label="Customer Primary Contact Name"
                                      name="primaryContactNameivc"
                                      type="text"
                                      placeholder="Input text"
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Customer Primary Email Address"
                                      name="primaryEmailAddressivc"
                                      type="text"
                                      placeholder="Input text"
                                    />
                                  </div>
                                </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <SelectField
                                      title={"Account Manager"}
                                      name="accountManagerivc"
                                      options={accountManager}
                                      customStyles={styles.obj}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "accountManagerEmailivc",
                                          value
                                        );
                                        //handleChange();
                                      }}
                                      additionalStyle={styles.additionalStyle}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    {/* <SelectField
                                          title={"Account Manager Email Address"}
                                          name="accountManagerEmailivc"
                                          options={accountManager.map((item) => ({
                                            value: accountManagerEmailMap[item.value],
                                            label: accountManagerEmailMap[item.value],
                                          }))}
                                          customStyles={styles.obj}
                                        /> */}
                                    <TextField
                                      label="Account Manager Email Address"
                                      name="accountManagerEmailivc"
                                      value={values.accountManagerEmailivc}
                                      type="text"
                                      placeholder=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            {(isIPXServicesChecked ||
                              isInternationalVoiceServicesChecked) && (
                              <>
                                <TextArea
                                  label={"Additional Information"}
                                  id="additionalInformation"
                                  name="additionalInformation"
                                  placeholder="Enter the information"
                                />
                                <ImageUpload
                                  name="files"
                                  value={values.files}
                                  setFieldValue={setFieldValue}
                                  optional={false}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Form>
                  <div className={styles.footer}>
                    <div>
                      <BackButton
                        className={styles.backButton}
                        style={styles.back}
                        title={"Back"}
                        closePopup={close}
                      />
                    </div>
                    {searchQuery?.length === 0 || filteredList?.length > 0 ? (
                      <></>
                    ) : (
                      <div>
                        {continueClicked ? (
                          <>
                            <Submit
                              id="saveButton"
                              className={styles.saveButton}
                              style={styles.submit}
                              title={"Submit"}
                              form="Add_Customer"
                              isSubmitting={isSubmit}
                              disabled={!dirty || !isValid}
                            />
                          </>
                        ) : (
                          <button
                            className={styles.continuebutton}
                            onClick={YesContinue}
                          >
                            Yes Continue
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Sidebar>
      )}
    </>
  );
};

export default NewCustomer;
