import { FETCH_USERS_LIST} from "../actionTypes";
import { fetchUserData } from "../../../api/userManagement/userViewer";

const request = () => ({ type: FETCH_USERS_LIST.REQUEST });
const failure = (error) => ({ type: FETCH_USERS_LIST.FAILURE, error });
const success = (data,isLoadMore,next) => ({ type: FETCH_USERS_LIST.SUCCESS,data,isLoadMore,next});



export const getUser= (type,custID,searchKey,next) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchUserData(type,custID,searchKey,next)
      .then((res) => {
        dispatch(success(res?.rights,!!next, res?.next));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
