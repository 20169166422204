import React, { useEffect, useState } from "react";
import { Container } from "../../../components/Container/Containers";
import { Formik, Field, Form, useField, ErrorMessage } from 'formik';
import DropDown from "../../../components/dropDown/DropDown";
import { DIAMETER, SIGTRAN, TRANSPORT, DNS, SERVICE_REQUEST_OPTIONS, GRX } from "../../../utils/constants/serviceConstants";
import Section from "../../../components/Section/Section"
import MultiDropDown from "../../../components/dropDown/MultiDropDown";
import styles from "./newService.module.css";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../components/label/Labels";
import { useNavigate } from "react-router-dom";
import { FormTextArea, TextInput } from "../../../components/input/inputs";
import * as Yup from "yup";
import { app } from '../../../configuration/gcp-identity-platform-config';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Submit, BackButton } from "../../../components/button/buttons";
// import { Alert } from "../../../components/alert/alerts";
import success from '../../../assets/images/success.png';
import Asterisk from "../../../components/asterisk/Asterisk";
import { USER_TYPE } from "../../../utils/constants/userConstants";
import FileAttachment from "../../../components/fileAttachment/fileAttachment.container";
import CAN from '../../../casl/can';
import { Action, Resource } from '../../../casl/constants';
import { Sidebar } from "../../../components/sidebar/Sidebar";
import IMAGES from "../../../assets/images/images";
import * as DOMPurify from 'dompurify';
import { RadioTabs } from "../../../components/tabs/RadioTabs";
import { Loader } from "../../../components/loader/Loader";
import SectionGuard from "../../../hoc/guards/SectionGuard";

let dropObj = {
  width: "auto"
}
const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const SelectField = ({ title, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
        <Label text={title} /><Asterisk />
      </div>
      <DropDown
        options={options}
        onChange={({ label, value }) => {
          props.onChange(value);
          helpers.setValue(value);
        }}
        value={
          ((options?.length > 0) && field.value)
            ? options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
        customStyles={dropObj}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextField = ({ label, disabled, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} /><Asterisk />
      </div>
      <TextInput {...field} {...props} style={inputStyle} disabled={disabled} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <Label text={label} optional={optional} />
      <FormTextArea {...field} {...props} style={inputStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const CheckBox = (name, ...props) => {

  return (
    <FieldWrapper>
      <label>
        <Field className={styles.checkboxs} type="checkbox" name={name.name} value={name.value} />
        <span className={styles.label}>{name.value}</span>
      </label>
    </FieldWrapper>
  )
}

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper >
      <Label text={label} optional={false} />
      <FileAttachment setFieldValue={setFieldValue} name={props.name} value={value} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};

const NewService = (props) => {
  const { liveServicesData, isLoading } = props;
  let [isSubmit, setIsSubmit] = useState(false);
  let [isAlert, setIsAlert] = useState(false);
  const [countryDropdown, setCountryDropdown] = useState({});
  const [regionDropdown, setRegionDropdown] = useState({});
  let [defaultActiveTab, setDefaultActiveTab] = useState(SERVICE_REQUEST_OPTIONS.CREATE_NEW);
  const [servicesDropdown, setServicesDropdown] = useState({});
  let [initialValues, setInitialValues] = useState({});
  let [selectedRegion, setSelectedRegion] = useState('');
  let [servicesDetails, setServicesDetails] = useState({});
  let [detailsData, setDetailsData] = useState({});
  let [selectedOrganization, setSelectedOrganization] = useState({});


  const getRegion = (pe_ref) => {
    if (pe_ref) {
      const obj = props.pops.find(({ hostName }) => hostName.toLowerCase() === pe_ref.toLowerCase());
      return obj?.region.value;
    }
  }

  const getLocationName = (pe_ref) => {
    const obj = props.pops.find(({ hostName }) => hostName.toLowerCase() === pe_ref);
    return obj?.city;
  }
  const getCountry = (pe_ref) => {
    const obj = props.pops.find(({ hostName }) => hostName.toLowerCase() === pe_ref);
    return obj?.country;
  }
  const closePopup = () => {    
    props.closeNewServiceForm();
  }

  let _initialValues = {
    organization: props.myRights?.cpName,
    location: "",
    service: "",
    country: "",
    avg_monthly_dtr_volumes: "",
    dns_number: "",
    avg_monthly_msu_volumes: "",
    top_destinations: "",
    checkBox: [],
    allocated_capacity: "",
    comment: "",
    files: []
  };

  if (!props.isAmendMode) { // New service form default values    
    _initialValues.organization = props.myRights?.cpName;
    _initialValues.location = "";
    _initialValues.country = "";
    _initialValues.service = "";
    _initialValues.avg_monthly_dtr_volumes = "";
    _initialValues.dns_number = "";
    _initialValues.avg_monthly_msu_volumes = "";
    _initialValues.top_destinations = "";
    _initialValues.checkBox = [];
    _initialValues.allocated_capacity = "";
    _initialValues.comment = "";
    _initialValues.files = []
  } else {    // Amend service form default values   
    _initialValues.organization = props.myRights?.cpName;
    let service = initialValues?.ipx_service;
    let city = getLocationName(initialValues?.pe_ref);
    let dropDownRegion = getRegion(initialValues?.pe_ref);
    _initialValues.location = dropDownRegion;
    _initialValues.country = getCountry(initialValues?.pe_ref);
    _initialValues.service = service ? service + '-' + city + '~' + initialValues?.ref3c : '';

  }

  const validationSchema = Yup.object({
    organization: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    service: Yup.string().required("Required"),
    avg_monthly_dtr_volumes: Yup.string().when("service", (service, schema) => {
      let selectedService = (service[0] !== undefined) ? service[0].split("-")[0] : service[0];
      selectedService = (selectedService != undefined) ? selectedService.toUpperCase() : selectedService;
      if (selectedService === DIAMETER)
        return schema.required("Required")

      return schema
    }),
    dns_number: Yup.string().when("service", (service, schema) => {
      let selectedService = (service[0] !== undefined) ? service[0].split("-")[0] : service[0];
      selectedService = (selectedService != undefined) ? selectedService.toUpperCase() : selectedService;
      if (selectedService === DNS)
        return schema.required("Required")
      return schema
    }),
    avg_monthly_msu_volumes: Yup.string().when("service", (service, schema) => {
      let selectedService = (service[0] !== undefined) ? service[0].split("-")[0] : service[0];
      selectedService = (selectedService != undefined) ? selectedService.toUpperCase() : selectedService;
      if (selectedService === SIGTRAN)
        return schema.required("Required")
      return schema
    }),
    top_destinations: Yup.string().when("service", (service, schema) => {
      let selectedService = (service[0] !== undefined) ? service[0].split("-")[0] : service[0];
      selectedService = (selectedService != undefined) ? selectedService.toUpperCase() : selectedService;
      if (selectedService === SIGTRAN)
        return schema.required("Required")
      return schema
    }),
    checkBox: Yup.array().when("service", (service, schema) => {
      let selectedService = (service[0] !== undefined) ? service[0].split("-")[0] : service[0];
      selectedService = (selectedService != undefined) ? selectedService.toUpperCase() : selectedService;
      if (selectedService === SIGTRAN)
        return schema.min(1, "Required")
      return schema
    }),
    allocated_capacity: Yup.string().when("service", (service, schema) => {
      let selectedService = (service[0] !== undefined) ? service[0].split("-")[0] : service[0];
      selectedService = (selectedService != undefined) ? selectedService.toUpperCase() : selectedService;
      if ((selectedService === TRANSPORT) || (selectedService === GRX))
        return schema.required("Required")
      return schema
    }),
    comment: Yup.string().max(1000, "Comment must be at most 1000 characters"),
    files: Yup.array().max(3)
  });

  // function to fetch regionadn country dropdown list

  const getLocationList = () => {
    // console.log("location--->",props.locations,"----region",region)
    let countryData = props.locations?.reduce(function (accObj, currentObj) {
      let regionName = currentObj.region.value + "~" + currentObj.region.label;
      accObj[regionName] = accObj[regionName] || [];
      if (!accObj[regionName].includes(currentObj.country))
        accObj[regionName].push(currentObj.country);
      return accObj;
    }, {});
    let regionDropdown = [];
    let countryDropDown = {};
    countryData && Object.keys(countryData).forEach((key) => {
      let regionObj = {};
      let reg = key.split("~")[0];
      regionObj.value = reg
      regionObj.label = key.split("~")[1];
      regionDropdown.push(regionObj);
      countryDropDown[reg] = [];
      Object.keys(countryData[key]).forEach((country) => {
        let obj = {};
        obj.value = countryData[key][country];
        obj.label = countryData[key][country];
        countryDropDown[reg].push(obj);
      });
    });
    setCountryDropdown(countryDropDown);
    setRegionDropdown(regionDropdown);
  }
  // fetching services dropdown 
  const filterDataByLocation = (liveServiceData, selectedRegion) => {

    if ((props.isAmendMode == true) && (props.myRights?.userType === USER_TYPE.CUSTOMER)) {
      let result = liveServiceData.reduce(function (accObj, currentObj) {
        let region = getRegion(currentObj.pe_ref);
        accObj[region] = accObj[region] || [];
        accObj[region].push(currentObj.ipx_service + "-" + getLocationName(currentObj.pe_ref) + "~" + currentObj.ref3c + "|" + currentObj.vlan + "|" + currentObj.logical_capacity_mbps + "|" + currentObj.pe_ref + "|" + getRegion(currentObj.pe_ref));
        return accObj;
      }, {});
      let serviceDropDown = {};
      let serviceDetail = {};
      Object.keys(result).forEach((key) => {
        serviceDropDown[key] = [];
        serviceDetail[key] = [];
        Object.keys(result[key]).forEach((service) => {
          let obj = {};
          let serviceLabel = result[key][service].split("~")[0].split("-");
          obj.value = result[key][service].split("|")[0];
          obj.label = serviceLabel[0].toUpperCase() + " (" + serviceLabel[1] + ")";
          obj.detail = result[key][service];
          serviceDropDown[key].push(obj);
          serviceDetail[key].push(obj);
        });
      });
      setServicesDetails(serviceDetail[selectedRegion]);
      setServicesDropdown(serviceDropDown[selectedRegion]);
      if (!selectedRegion) {
        let newDropDown = [];
        Object.keys(serviceDropDown).forEach((region) => {
          Object.keys(serviceDropDown[region]).forEach((index) => {
            newDropDown.push(serviceDropDown[region][index])
          });
        });
        setServicesDropdown(newDropDown);
        setServicesDetails(newDropDown);
      }

    }
  }

  useEffect(() => {
    props.getLocations();

    if (props.myRights?.userType === USER_TYPE.INTERNAL) {
      props.getCustomers();
    }
    props.getServices()

    props.getLiveServices('', '');

  }, [])

  useEffect(() => {
    getLocationList();
  }, [props.locations])

  useEffect(() => {
    if (props.isAmendMode == true) {
      // props.setIsAmendMode(true); 
      setDefaultActiveTab(SERVICE_REQUEST_OPTIONS.AMMEND_EXISTING_SERVICE);
      setInitialValues(props.initialValue);
      setSelectedRegion(selectedRegion = getRegion(props.initialValue?.pe_ref));
      filterDataByLocation(liveServicesData, selectedRegion);
      setDetailsData(detailsData = props.initialValue);
    } else {
      // props.setIsAmendMode(false);
      setInitialValues(initialValues = {});
      setSelectedRegion(selectedRegion = '');
      setDefaultActiveTab(SERVICE_REQUEST_OPTIONS.CREATE_NEW);
      // setServicesDropdown(props.services);     
      setDetailsData(detailsData = {});
    }
  }, [props.isAmendMode, props.initialValue])

  const setActiveRadio = (mode) => {
    props.setIsAmendMode(mode);
  }

  const _options = [
    { value: "10 Gbps", label: "10 Gbps" },
    { value: "20 Gbps", label: "20 Gbps" },
    { value: "30 Gbps", label: "30 Gbps" },
    { value: "40 Gbps", label: "40 Gbps" },
    { value: "50 Gbps", label: "50 Gbps" },
    { value: "60 Gbps", label: "60 Gbps" },
    { value: "70 Gbps", label: "70 Gbps" },
    { value: "80 Gbps", label: "80 Gbps" },
    { value: "90 Gbps", label: "90 Gbps" },
    { value: "100 Gbps", label: "100 Gbps" },

  ]

  const navigate = useNavigate();

  const serviceList = () => {
    navigate("/connectivity");
  }

  const closeSidebar = () => {
    props.setIsAmendMode(false);
    props.closeNewServiceForm();
  }

  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });
  const AmmendServiceDetail = ({ data, city, country, region }) => {
    return (
      <div className={styles.topSection}>
        <div className={styles.topSectionDiv}>
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>VLAN ID:&nbsp;</div>
            <div className={styles.requestorvalue}>{data?.vlan}</div>
          </div>
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Router City:&nbsp;</div>
            <div className={styles.servcevalue}>{city}</div>
          </div>
        </div>
        <div className={styles.topSectionDiv}>
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Router Name:&nbsp;</div>
            <div className={styles.requestorvalue}>{data?.pe_ref}</div>
          </div>
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Allocated Capacity (Mbps):&nbsp;</div>
            <div className={styles.servcevalue}>{data?.logical_capacity_mbps}</div>
          </div>
        </div>
        <div className={styles.topSectionDiv}>
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Region:&nbsp;</div>
            <div className={styles.requestorvalue}>{regionDropdown.find(location => location.value == region)?.label}</div>
          </div>
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Country:&nbsp;</div>
            <div className={styles.servcevalue}>{country}</div>
          </div>
        </div>
      </div>
    );
  }

  const updateContent = (value) => {
    let selectedServiceDetail = servicesDetails.length
      ? servicesDetails?.find((option) => option.value === value)
      : ""; // mapping with live service id    
    let serviceDetail = selectedServiceDetail.detail.split("~");
    let vlan = serviceDetail ? serviceDetail[1].split("|")[1] : '';
    let capacity = serviceDetail ? serviceDetail[1].split("|")[2] : '';
    let routerName = serviceDetail ? serviceDetail[1].split("|")[3] : '';
    let service = serviceDetail ? serviceDetail[0].split("-")[0] : '';
    let serviceId = serviceDetail ? serviceDetail[1].split("|")[0] : '';
    let region = serviceDetail ? serviceDetail[1].split("|")[4] : '';
    // Updating service Detail data to be shown on pop up for amend service
    let detailData = {};
    detailData.logical_capacity_mbps = capacity ? capacity : '';
    detailData.vlan = vlan ? vlan : '';
    detailData.pe_ref = routerName ? routerName : '';
    detailData.ipx_service = service ? service : '';
    detailData.live_service_id = serviceId ? serviceId : '';
    detailData.organization = props.myRights?.cpName;
    setDetailsData(detailData);
    setSelectedRegion(selectedRegion = getRegion(routerName))
    _initialValues.country = getCountry(routerName);
  }

  const updateCountryList = (value) => {
    setSelectedRegion(selectedRegion = value);
  }

  return (
    <div>
    <Sidebar
    isOpen={props.isServiceFromOpen}
    onClose={closeSidebar}
    headerTxt={!props.isAmendMode ? "Raise New Demand" : "Amend Existing Service"}
  >
    <SectionGuard canAccess={CAN(Action.LIST,Resource.LIVE_SERVICES) && CAN(Action.CREATE,Resource.DEMANDS) && props.myRights?.userType !== USER_TYPE.INTERNAL}>
    <RadioTabs defaultActiveTab={!props.isAmendMode ? SERVICE_REQUEST_OPTIONS.CREATE_NEW : SERVICE_REQUEST_OPTIONS.AMMEND_EXISTING_SERVICE} options={[SERVICE_REQUEST_OPTIONS.CREATE_NEW, SERVICE_REQUEST_OPTIONS.AMMEND_EXISTING_SERVICE]} setActiveRadio={setActiveRadio} />
    </SectionGuard>
      <Formik
        initialValues={_initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={async (values,{ resetForm }) => {
          let obj = {"service_option" : {}, "files": [], "additional_info": {}, "locationDetails":{}};
          obj.organization = values?.organization;          
          let service = values?.service;
          obj.uniqueCustomerRefId = props.myRights.userType===USER_TYPE.CUSTOMER?props.myRights?.customerUserMetaInfo?.uniqueCustomerRefId:selectedOrganization?.uniqueCustomerRefId;
          if (props.isAmendMode == true) {
            let serviceData = values?.service.split("-");          
            let serviceName = serviceData[0]?serviceData[0].toUpperCase():'';
            service = (serviceName==GRX)?TRANSPORT:serviceName;
          }
                             
          let regionLabel = regionDropdown.find(({ value }) => value.toLowerCase() === values?.location.toLowerCase())?.label;
          obj.locationDetails.serviceRegion = {'label': regionLabel,'value':values?.location};
          obj.locationDetails.serviceCountry = {'label': values?.country,'value':values?.country};
          
          obj.service = service;
          if (service === DIAMETER) {
            obj.service_option.avg_monthly_dtr_volumes = DOMPurify.sanitize(values?.avg_monthly_dtr_volumes);
          } else if (service === DNS) {
            obj.service_option.dns_number = DOMPurify.sanitize(values?.dns_number);
          } else if (service === SIGTRAN) {
            obj.service_option.type = values?.checkBox;
            obj.service_option.avg_monthly_msu_volumes = DOMPurify.sanitize(values?.avg_monthly_msu_volumes);
            obj.service_option.top_destinations = DOMPurify.sanitize(values?.top_destinations);
          } else if ((service === TRANSPORT) || (service === GRX)) {
            obj.service_option.allocated_capacity = DOMPurify.sanitize(values?.allocated_capacity);
          }  
                
          obj.comment = DOMPurify.sanitize(values?.comment);
          obj.status = "received";
          obj.files = values?.files;
          if ((props.isAmendMode == true) && (props.myRights?.userType === USER_TYPE.CUSTOMER)) {
            let selectedServiceDetail = servicesDetails.length
            ? servicesDetails?.find((option) => option.value === values.service)
            : ""; // mapping with live service id  
            let serviceDetail = selectedServiceDetail.detail.split("~");          
            let routerName = serviceDetail ? serviceDetail[1].split("|")[3] : '';            
            obj.additional_info.vlan = serviceDetail ? serviceDetail[1].split("|")[1] : '';
            obj.additional_info.physical_capacity_gbps = serviceDetail ? serviceDetail[1].split("|")[2] : '';
            obj.additional_info.pe_ref = routerName;
            obj.additional_info.router_location = getLocationName(routerName);
            obj.additional_info.service_id = serviceDetail ? serviceDetail[1].split("|")[0] : '';
            obj.organization = props.myRights?.cpName;
          }
          setIsSubmit(true);
          props.submitRequest(obj).then((result) => {
            if (result.status) { 
              // setMessage({
              //   message: `A new service request with ID: ${result.res.requestId} has been created`,
              //   image: IMAGES.success
              // });           
             setIsSubmit(false);  
             resetForm();   
             props.setIsAmendMode(false);        
             props.closeNewServiceForm();
             //setIsAlert(true);
             props.openAlertPopup({
                message: `A new demand with ID: ${result.res.requestId} has been created`,
                image: IMAGES.success
             })
             props.getServicesList();
            }else{
              setIsSubmit(false);
              let error_msg = result?.error ??  "Something went wrong.";
              props.setIsAmendMode(false);
              props.closeNewServiceForm();
              // setMessage({
              //   message: error_msg,
              //   image: IMAGES.error
              // });
              // setIsAlert(true);
              props.openAlertPopup({
                message: error_msg,
                image: IMAGES.error
             })
            }
          })
          .catch((error) => {
            setIsSubmit(false);
           
                  // setMessage({
                  //   message: "Something went wrong.",
                  //   image: IMAGES.error
                  // });
                  // setIsAlert(true);
                  props.openAlertPopup({
                    message: "Something went wrong.",
                    image: IMAGES.error
                 })
                  props.setIsAmendMode(false);
                  props.closeNewServiceForm();
          });

          }}
        >
          {({ isValid, dirty, values, setFieldValue }) => (

            <Form>
              {
                props.myRights?.userType === USER_TYPE.INTERNAL ?
                  <SelectField
                    title={"Organization"}
                    name="organization"
                    isAmendMode={props.isAmendMode}
                    options={props.organizations?.map((item) => ({ value: item?.cpName, label: item?.cpName }))}
                    onChange={(value) =>{
                      let selectedOrg = props.organizations.find(item => item.cpName === value);
                      setSelectedOrganization(selectedOrg)
                    }}
                  /> : <></>
              }

              {
                (((props.isAmendMode == true) && (props.myRights?.userType !== USER_TYPE.INTERNAL))) ?
                  (Object.keys(servicesDropdown).length == 0) ? <Loader style={styles.btnLoader} /> :
                    <SelectField
                      title={"Search service you want to amend"}
                      name="service"
                      isAmendMode={props.isAmendMode}
                      options={servicesDropdown}
                      onChange={(value) => {
                        let selectedService = servicesDetails.length
                          ? servicesDetails?.find((option) => option.value === value)
                          : ""; // mapping with live service id 
                        let serviceDet = selectedService?.detail.split("|");
                        let region = serviceDet[4];
                        let countryList = selectedService?.value.split("~")[0];
                        let country = getCountry(serviceDet[3]);
                        setFieldValue('location', region);
                        setFieldValue('country', country);
                        setSelectedRegion(selectedRegion = region);
                        updateContent(value)
                      }}
                    /> :
                  <SelectField
                    title={"Service"}
                    name="service"
                    isAmendMode={props.isAmendMode}
                    options={props.services}
                    onChange={(value) => console.log("did it")}
                  />
              }

              {((props.isAmendMode == true) && values.service && (detailsData.service !== "undefined") && (props.myRights?.userType !== USER_TYPE.INTERNAL)) ?
                <AmmendServiceDetail data={detailsData} city={getLocationName(detailsData.pe_ref)} country={values.country} region={values.location}/> :
                <></>
              }
              {

                (Object.keys(regionDropdown).length == 0) ? <Loader style={styles.btnLoader} /> :
                 props.isAmendMode == false&&(
                  <SelectField
                  title={"Region"}
                  name="location"
                  isAmendMode={props.isAmendMode}
                  options={regionDropdown}
                  onChange={(value) => {
                    setFieldValue('country', '');
                    updateCountryList(value, setFieldValue)

                  }}
                  // isDisabled={((props.isAmendMode === true) && selectedRegion != "") ? true : false}
                  // customStyles={((props.isAmendMode === true) && selectedRegion != "") ? 'disabledInput' : ''}
                />
                 )
              }
              {props.isAmendMode == false&&(
                <SelectField 
                 title={"Country"}
                 name="country"
                 isAmendMode={props.isAmendMode}
                 options={((props.isAmendMode === true) && (props.myRights?.userType === USER_TYPE.CUSTOMER) && (selectedRegion != "")) ?
                  countryDropdown[selectedRegion] : countryDropdown[selectedRegion]}
                //  isDisabled={((props.isAmendMode === true) && selectedRegion != "") ? true : false}
                 onChange={(value) => console.log("did it")}
                />
              )}
              {
                (((props.isAmendMode == true) && (props.myRights?.userType !== USER_TYPE.INTERNAL) && (values?.service?.split("-")[0]?.toUpperCase() == DIAMETER)) || (values?.service === DIAMETER)) ?
                  <TextField
                    label="Avg Monthly DTR Volumes"
                    name="avg_monthly_dtr_volumes"
                    type="text"
                    placeholder="Avg Monthly DTR Volumes"
                  /> : (((props.isAmendMode == true) && (props.myRights?.userType !== USER_TYPE.INTERNAL) && (values?.service?.split("-")[0]?.toUpperCase() == DNS)) || (values?.service === DNS)) ?
                    <TextField
                      label="How Many DNS"
                      name="dns_number"
                      type="number"
                      placeholder="How Many DNS"
                    /> : (((props.isAmendMode == true) && (props.myRights?.userType !== USER_TYPE.INTERNAL) && (values?.service?.split("-")[0]?.toUpperCase() == SIGTRAN)) || (values?.service === SIGTRAN)) ?
                      <React.Fragment>

                        <Label
                          text={"Select Category"}
                        /><Asterisk />
                         <FieldWrapper>
                        <div className={styles.checkboxalign}>
                          <CheckBox
                            name="checkBox"
                            value="On-Net" />
                          <CheckBox
                            name="checkBox"
                            value="Off-Net" />
                          <CheckBox
                            name="checkBox"
                            value="ANSI" />
                        </div>
                        <ErrorMessage name={"checkBox"}>
                          {(msg) => <ErrorLabel text={msg} />}
                        </ErrorMessage>
                      </FieldWrapper>
                      <TextField
                        label="Avg Monthly MSU Volumes"
                        name="avg_monthly_msu_volumes"
                        type="text"
                        placeholder="Avg Monthly MSU Volumes"
                      />
                      <TextField
                        label="Top Destinations"
                        name="top_destinations"
                        type="text"
                        placeholder="Top Destinations"
                      />
                    </React.Fragment> : (((props.isAmendMode == true) && (props.myRights?.userType !== USER_TYPE.INTERNAL) && ((values?.service?.split("-")[0]?.toUpperCase() == TRANSPORT) || (values?.service?.split("-")[0]?.toUpperCase() == GRX))) || ((values?.service === TRANSPORT) || (values?.service === GRX))) ?
                      <SelectField
                        title={"Allocated Capacity"}
                        name="allocated_capacity"
                        options={_options}
                        onChange={(value) => console.log("did it")}     
                      /> : <></>
            }
            {
              values.service?
              <React.Fragment><TextArea
              label={"Comment"}
              id="description"
              name="comment"
            /><div className={styles.commentValidation}>1000 characters</div></React.Fragment>:<></>
            
            }
            <ImageUpload
              name="files"
              value={values.files}
              setFieldValue={setFieldValue}
              optional={false}
            />
           <hr className={styles.separator} />
           <ButtonWrapper>
              <BackButton className={styles.backButton}
                style={styles.submit}
                title={"Back"} closePopup={closePopup} 
              />
              <Submit
                  style={styles.submit}
                  title={"Submit"}
                  isSubmitting={isSubmit}
                  disabled={!isValid || !dirty}
              />
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
      </Sidebar>

      {/* {isAlert && (
  <div
    style={{
      zIndex: 20,
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
    }}
  >    
    <Alert
      image={message.image}
      message={message.message}
      onClose={setIsAlert}
    />
  </div>
)} */}
    </div>
  )
};


export default NewService;
