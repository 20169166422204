import { GoogleMap, InfoWindowF, Marker, Polygon, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useMemo, useState } from "react";
import style from "./Map.module.css";
import IMAGES from "../../assets/images/images";
import { Popover } from 'react-tiny-popover'
import { Loader } from "../loader/Loader";
import { LIVE_SERVICE_STATUS } from "../../utils/constants/serviceConstants";
import { customGroupBy } from "../../utils/arrayUtils";

const CountryMap = ({ country, marker, apiKey }) => {
    const [coordinates, setCoordinates] = useState([]);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
    });
    useEffect(() => {
        const getCountryCoordinates = async () => {
            try {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${country}&key=${apiKey}`
                );

                if (!response.ok) {
                    //console.log("Failed");
                } else {
                    const data = await response.json();
                    const geometry = data?.results[0].geometry;
                    if (geometry && geometry.bounds) {
                        const bounds = geometry.bounds;
                        const northeast = bounds.northeast;
                        const southwest = bounds.southwest;
                        const countryOutline = [
                            { lat: northeast.lat, lng: southwest.lng },
                            { lat: northeast.lat, lng: northeast.lng },
                            { lat: southwest.lat, lng: northeast.lng },
                            { lat: southwest.lat, lng: southwest.lng },
                        ]
                        setCoordinates(countryOutline);
                    }

                }

            } catch {
                //console.log("Failed");
            }
        }
        getCountryCoordinates();
    }, [country, marker, apiKey]);
    return (
        <>
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <div className={{ "height": "100px", "width": "200px" }}>
                    <GoogleMap
                        mapContainerClassName={{ "height": "100px", "width": "200px" }}
                        center={{ lat: 10, lng: 10 }}
                        zoom={0}
                    >
                        {
                            coordinates.length && (
                                <Polygon
                                    path={coordinates}
                                    options={{
                                        strokeColor: '#FF0000',
                                        strokeOpacity: 1,
                                        strokeWeight: 2,
                                        fillColor: '#FF0000',
                                        fillOpacity: 0,
                                    }}
                                />
                            )
                        }
                    </GoogleMap>
                </div>
            )
            }
        </>
    )
}

const TooltipContent = ({ closeTooltip, infoWindowId,showMyConnectivity, ...props }) => {
    const [tooltipPosition, setTooltipPosition] = useState("");
    const [openIndexDiv, setOpenIndexDiv] = useState(-1);
    useEffect(() => {
        let data = JSON.parse(infoWindowId?.latLong);
        if (Math.sign(Number(data.lng)) == 1) {
            setTooltipPosition("left");
        } else {
            setTooltipPosition("right")
        }
    }, [infoWindowId])

    let rawData =infoWindowId?.customers?.length ? infoWindowId.customers:[]
    const groupByCustomer= customGroupBy(rawData,'cp_name')

    const toggleDiv = (index) => {
        setOpenIndexDiv(prevIndex => (prevIndex === index ? -1 : index));
    }

    return (
        <div className={tooltipPosition === "left" ? `${style.popoverContainerLeft}` : `${style.popoverContainerRight}`} style={{}}>
            <div className={style.closeIconContainer}>
                <div>
                    <img className={style.close} src={IMAGES.close} onClick={closeTooltip} />
                </div>
    </div>

            <div className={style.popoverHeader}>
                <div>
                    <img className={style.headerIcon} src={showMyConnectivity && infoWindowId?.isAvailedService? IMAGES.availedTierIcon : infoWindowId.marker} />
                </div>
                <div>
                    <div className={style.headerTitle}>{infoWindowId?.city}</div>
                    <div className={style.headerSubTitle}>{infoWindowId?.country}</div>
                </div>
            </div>
            <div className={style.fieldContainer}>
                <div className={style.fieldLabel}>Location</div>
                <div className={style.fieldvalue}>{infoWindowId?.location}</div>
            </div>
            <div className={style.fieldContainer}>
                <div className={style.fieldLabel}>Host name</div>
                <div className={style.fieldvalue}>{infoWindowId?.hostName}</div>
            </div>
            <div>
                {
                    infoWindowId?.customers ?
                        <div>
                            <div className={style.hrLine}></div>
                            <div className={style.customerSection}>Customer list</div>
                            <div className={style.collapsibleListScroll}>
                                {
                                    infoWindowId?.customers.length ?
                                    (Object.keys(groupByCustomer).sort().map((key, index) => {
                                        return (
                                            <div key={index} className={style.collapsibleList}>
                                                <div className={style.collapsibleHeader} onClick={() => toggleDiv(index)}>
                                                    <div className={style.collapsibleHeaderTitle}>
                                                        {key}
                                                    </div>
                                                    <div className={style.collapsibleHeaderIcon}>
                                                        <img src={openIndexDiv === index ? IMAGES.chevronOpen : IMAGES.chevronClose} />
                                                    </div>
                                                </div>
                                                <div className={openIndexDiv === index ? style.showcollapsibleContent : style.hidecollapsibleContent}>
                                                {
                                                    groupByCustomer[key].map((item,itemIndex) => {
                                                        return (
                                                            <div key={itemIndex} className={ style.itemContainer}>
                                                                <div className={style.showcollapsibleContentItem1}>
                                                                    {item?.ipx_service?.toUpperCase()}
                                                                </div>
                                                                <div className={parseStatus(item?.bgp_status) === LIVE_SERVICE_STATUS.Live ? `${style.liveService} ${style.showcollapsibleContentItem2}` : `${style.shutdownService} ${style.showcollapsibleContentItem2}`}>
                                                                    {parseStatus(item?.bgp_status)}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                                :
                                    <div className={style.noDataMsg}>No Data.</div>
                                }
                            </div>

                        </div> : <></>
                }
            </div>
        </div>
    )
}

const parseStatus=(status)=>{
    let _newStatus=''
        if(status==LIVE_SERVICE_STATUS.No_Shutdown){
            _newStatus=LIVE_SERVICE_STATUS.Live 
        }else if(status==LIVE_SERVICE_STATUS.Shutdown){
            _newStatus=LIVE_SERVICE_STATUS.Shutdown 
        }
    return _newStatus;
}
const HoverTooltipContent = ({ data,...props }) => {
    return (
        <div className={style.hoverTooltipContainer}>
            <div className={style.popoverTitle}>{data?.city}</div>
        </div>
    )
}

export const Map = ({ markers,showMyConnectivity, ...props }) => {
    const [hoverMarkerData, setHoverMarkerData] = useState(null);
    const [infoWindowId, setInfoWindowId] = useState(null);
    const [center, setCenter] = useState({lat: 45, lng: 10});
    const defaultZoom=2;

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });
    const restrictedCoordinate = {
       /* north: 85,
        south: -180,
        west: -85,
        east: 110,*/
      // north: 85, south: -85, west: -179.99, east: 179.999
      north: 85, south: -85, west: -180, east: 180
    };

   const handleMarkerMouseOver = (item) => {
         setHoverMarkerData(item)
    }
    const handleMarkerMouseOut = () => {
        setHoverMarkerData(null)
    }
    
    const showTooltip = (item) => {
        setInfoWindowId(item);
    }
    const closeTooltip = () => {
        setInfoWindowId(null);
    }

    const getMarkerScale = (hoverMarkerData, infoWindowId, item, isAvailedService, showMyConnectivity) => {
        switch (true) {
            // my connnectivity default size
            case (showMyConnectivity && isAvailedService && hoverMarkerData?.id != item.id && infoWindowId?.id != item.id):
                return new window.google.maps.Size(30, 30);
            // my connnectivity on hover or click size
            case (showMyConnectivity && isAvailedService && (hoverMarkerData?.id === item.id || infoWindowId?.id === item.id)):
                return new window.google.maps.Size(50, 50);
            // other marker default size
            case (hoverMarkerData?.id != item.id && infoWindowId?.id != item.id):
                return new window.google.maps.Size(20, 20);
            // other marker  on hover or click size
            case (hoverMarkerData?.id === item.id || infoWindowId?.id === item.id):
                return new window.google.maps.Size(40, 40);
            default:
                return new window.google.maps.Size(20, 20);
        }
    }
    return (
        <div className={style.App}>
            {!isLoaded ? (
                <Loader/>
            ) : (
                <GoogleMap
                    mapContainerClassName={style.mapContainer}
                    center={ center }
                    zoom={defaultZoom}
                    options={
                        {
                          gestureHandling: "greedy",  // added to remove overaly scroll to zoom the map
                            disableDefaultUI: true,
                            zoomControl:true,
                            zoomControlOptions: {
                                position:  window.google.maps.ControlPosition.LEFT_TOP,
                            },
                            restriction: {
                                latLngBounds: restrictedCoordinate,
                                strictBounds: false,
                            },
                            styles: [
                               /* {
                                    featureType: "all",
                                    elementType: "all",
                                    stylers: [
                                        { color: "#FFFFFF" }
                                    ]
                                },*/
                                {
                                    featureType: "all",
                                    elementType: "geometry",
                                    stylers: [
                                        { color: "#373737" }
                                    ]
                                },
                                /*--------Water----------*/
                                {
                                    featureType: "water",
                                    elementType: "all",
                                    stylers: [{ color: "#25282B" }]
                                },
                                {
                                    featureType: "water",
                                    elementType: "labels.text.fill",
                                    stylers: [
                                        { color: "#ffffff" }
                                    ]
                                },
                                {
                                    featureType: "water",
                                    elementType: "labels.text.stroke",
                                    stylers: [{ visibility: "off"  }]
                                },
                                /*----Hide all line --*/
                                {
                                    featureType: "administrative", 
                                    elementType: "all",    
                                    stylers: [{ visibility: "off" }]
                                },
                                {
                                    featureType: "administrative.country", 
                                    elementType: "geometry.stroke",    
                                    stylers: [{ visibility: "on" },
                                    { color: "#25282B"  }
                                ]
                                },
                                /*---CSS for country city fonts----*/
                                {
                                    featureType: "administrative.country",
                                    elementType: "labels.text",
                                    stylers: [{ visibility: "on" }]
                                },
                                {
                                    featureType: "administrative.province",
                                    elementType: "labels.text",
                                    stylers: [{ visibility: "on" }]
                                },
                                {
                                    featureType: "administrative.locality",
                                    elementType: "labels.text",
                                    stylers: [{ visibility: "on" }]
                                },
                                {
                                    featureType: "administrative",
                                    elementType: "labels.text.fill",
                                    stylers: [{ color: "#ffffff" }]
                                },
                                {
                                    featureType: "administrative",
                                    elementType: "labels.text.stroke",
                                    stylers: [{ visibility: "off"  }]
                                },
                                /*---end----*/
                                
                                {
                                    featureType: "landscape.man_made",
                                    elementType: "all",
                                    stylers: [
                                        { color: "#606466" }
                                    ]
                                },
                                {
                                    featureType: "landscape.natural.landcover",
                                    elementType: "all",
                                    stylers: [
                                        { color: "#606466" }
                                    ]
                                },
                                {
                                    featureType: "road",
                                    stylers: [
                                        { visibility: "off" }
                                    ]
                                },
                                {
                                    featureType: "poi",
                                    stylers: [
                                        { visibility: "off" }
                                    ]
                                }

                            ],
                            minZoom: defaultZoom,
                        }
                    }
                >
                    {
                        markers.map((item, index) => {
                            let data = JSON.parse(item?.latLong);
                            let isAvailedService = item?.isAvailedService ? true : false
                            let hasCustomerField = item?.customers ? true : false  // true only for internal user
                            return (
                                <Marker
                                    key={index}
                                    onMouseOver={() => handleMarkerMouseOver(item)}
                                    onMouseOut={() => handleMarkerMouseOut()}
                                    onClick={()=>showTooltip(item)}
                                    position={{ lat: Number(data?.lat), lng: Number(data?.lng) }}
                                    /*icon={{
                                        url: isAvailedService && infoWindowId?.id  === item.id ?
                                            IMAGES.companyPopsHover : isAvailedService && infoWindowId?.id  !== item.id ? IMAGES.companyPops :
                                                !isAvailedService && infoWindowId?.id  === item.id ? IMAGES.vfPopsHover : IMAGES.vfPops,
                                        //scaledSize: new window.google.maps.Size(10, 10), // scaled size
                                       // origin: new window.google.maps.Point(0,0), // origin
                                       // anchor: new window. google.maps.Point(0, 0) // anchor
                                        
                                    }}*/
                                    icon={{
                                        url: showMyConnectivity && isAvailedService?IMAGES.availedTierIcon:item.marker,
                                        scaledSize: getMarkerScale(hoverMarkerData,infoWindowId,item,isAvailedService,showMyConnectivity)
                                        //scaledSize: hoverMarkerData?.id === item.id || infoWindowId?.id === item.id ? new window.google.maps.Size(40, 40) : new window.google.maps.Size(20, 20)
                                    }}
                                >
                                    {
                                        (hoverMarkerData?.id === item.id) &&
                                        (
                                            <InfoWindowF >
                                                <HoverTooltipContent
                                                    data={hoverMarkerData} />
                                            </InfoWindowF>
                                        )

                                    }
                                </Marker>
                            )
                        })

                    }
                    {/*
                        (infoWindowId) && (
                            <Popover
                            isOpen={true}
                                reposition={true}
                                transform={{ top: 20, left: 20 }}
                                transformMode='relative'
                                positions={["center"]} // preferred positions by priority
                                content={
                                    <TooltipContent 
                                    closeTooltip={closeTooltip}
                                    infoWindowId={infoWindowId}/>
                                }
                          >
                                <></>
                            </Popover>)

                            */}

                </GoogleMap>
            )}
            {
                (infoWindowId) &&
                (<div>
                    <TooltipContent
                        closeTooltip={closeTooltip}
                        infoWindowId={infoWindowId}
                        showMyConnectivity={showMyConnectivity}/>
                </div>)

            }

        </div>
    );
};

