import styles from "./list.module.css"
import {serviceStatusColor,SERVICE_STATUS_OPTIONS} from "../../../src/utils/constants/serviceConstants";
import { useNavigate } from "react-router-dom";

export const ServiceDetailContainer = ({children, statusName})=>{
    var borderColor = Object.keys(serviceStatusColor).find(key => serviceStatusColor[key] === statusName.trim());
    
    return (
        <div className={styles.serviceDetailContainer} style={{ border: `solid 1px ${borderColor}` }}>{children }</div>
    );
}
export const ServiceHeader = ({children}) => {   
    return (
        <div className={styles.serviceHeader}>{children}</div>
    );

}

export const ServiceName = ({serviceName}) => {  
    return (
        <div className={styles.serviceName}>{serviceName}</div>                
    );
}

export const ServiceMiddleDiv = ({children})=>{
    return (
        <div className={styles.serviceMiddleDiv}>{children}</div>
    );
}

export const ServiceDescription = ({description}) => {  
    return (
        <div className={styles.descriptionContainer}>{description}</div>                
    );
}

export const DateOfSubmission = ({date}) => {  
    return (
        <div ><span className={styles.submittedOn}>Submitted On:&nbsp;</span> <span className={styles.createdDate}>{date}</span></div>                
    );
}

export const ServiceFooter = ({companyName, location, requestId, createdBy, createdByEmail, goToServiceDetails, displayId, isInternalUser, country}) => {  
    return (
        <div className={styles.serviceFooter} >
            { isInternalUser?
                <div className={styles.companyDiv }><span>Company Name: &nbsp;</span><span>{companyName}</span></div>
                : <></>
            }          
            <div className={styles.requestId}><span class="serviceRequest" id={requestId}>Demand ID: &nbsp;</span><span>{displayId}</span></div>
            <div className={styles.location}><span>Region:&nbsp;</span><span> {location}</span></div>
            <div className={styles.country}><span>Country:&nbsp;</span><span> {country}</span></div>
            <div className={styles.requestor}><span>Requestor: &nbsp;</span><span>{createdBy}</span></div>
        </div>                
    );
}

export const ServiceListContainer = ({children})=>{
    return (
        <div className={styles.serviceListContainer}>{children}</div>
    );
}

export const StatusContainer = ({statusName}) => {
    var backgroundColor = Object.keys(serviceStatusColor).find(key => serviceStatusColor[key] === statusName.trim());
    const obj = SERVICE_STATUS_OPTIONS.find(({ value }) => value ===statusName);
    return (
        <div className={styles.statusContainer} style={{ backgroundColor: `${backgroundColor}` }}>{obj?.label}</div>
    ); 
}


export const IconsContainer = ({children})=>{
    return (
        <div className={styles.iconsContainer}>{children}</div>
    );
}



