import { Field, Form, Formik,useField,ErrorMessage } from "formik";
import React, { useId, useState } from "react";
import * as Yup from "yup";
import Asterisk from "../../../../components/asterisk/Asterisk";
import { BackButton, Submit } from "../../../../components/button/buttons";
import { TextInput } from "../../../../components/input/inputs";
import IMAGES from "../../../../assets/images/images";  
import { FormFieldLabel as Label,FormErrorLabel as ErrorLabel } from "../../../../components/label/Labels";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import styles from "./addAccountManager.module.css";
import { useEffect } from "react";
import Tooltip from "../../../../components/toolTip/toolTip";
import {connect} from "react-redux";
import { openAlertPopup } from "../../../../redux/actions/Alert/alert.action";
import DropDown from "../../../../components/dropDown/DropDown";
import { getAccountManagerList } from "../../../../redux/actions/userManagement/submitOnbardingRequest";
import { AddNewAccountManager } from "../../../../redux/actions/userManagement/addnewaccountManager.action";
import { getCustomerDetails } from "../../../../redux/actions/userManagement/customer.action";

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? styles.inputError
    : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
        <Asterisk />
      </div>
      <TextInput  {...field}  {...props} placeholder={placeholder}  style={`${inputStyle} ${styles.inputStyle} `}   onChange={props.onChange} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const SelectField = ({ title, options, ...props }) => {
    let _options = options.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    const [field, meta, helpers] = useField(props);
  
    const hasError = meta.touched && meta.error ? true : false;
    return (
      <FieldWrapper>
        <div>
          <Label
            text={title}
            style={`${styles.labelcompLevel} ${props.additionalStyle}`}
          />
          <span className={styles.circlered}>
            <span className={styles.tooltip}>
              {props.name === "ServiceImpacted" ? <Asterisk /> : <></>}
            </span>
          </span>
        </div>
        <DropDown
          options={_options}
          placeholder={props.placeholder}
          customStyles={props.customStyles}
          onChange={({ label, value }) => {
            props.onChange(value);
            helpers.setValue(value);
          }}
          value={
            _options && field.value
              ? _options.find((option) => option.value === field.value)
              : ""
          }
          hasError={hasError}
          isDisabled={props.isDisabled}
        />
        {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };


const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};



const AddAccountManager = (props) => {
  const {functionalRoles}=props;
  const [showAddUser, setShowAddUser] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  
  const close = () => setShowAddUser(false);

  useEffect(() => {
    props.getAccountManagerList();
  }, []);

  const initialValues = {
    accountManager: "",
    accountManagerEmail: "",
        };

  const validationSchema = Yup.object().shape({
    accountManager: Yup.string().required("Account Manager is required"),
    accountManagerEmail: Yup.string().required("Account Manager Email is required"),
  });
     
  const accountManagerList = props?.accountManagerList;

  const accountManager = props.filteredAccountManager;

  const getAccountManagerName = (email) => {
    let accountManagerName = accountManager.filter(
      (item) => item.value == email
    );
    return accountManagerName[0].label;
  };

  return (
    <div>
     
      <button
        onClick={() => setShowAddUser(true)}
        className={styles.addButton}
      >
        Add New Account Manager
      </button>
    
      {showAddUser && (
        <Sidebar
          isOpen={showAddUser}
          headerTxt="Add New Account Manager"
          onClose={close}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={async (values, { resetForm }) => {    
            let  obj = {
                    uniqueCustomerRefId: props?.customerData?.uniqueCustomerRefId,
                    accountManagerDetails:
                    {
                      name: getAccountManagerName(values.accountManager),
                      email:values.accountManagerEmail,
                    }
                  }
        setIsSubmit(true);
       
        props.AddNewAccountManager(obj).then((result) => {
            if (result.status) {
              props.openAlertPopup({
                message: "Account Manager has been added successfully!",
                image: IMAGES.success,
              });
              setIsSubmit(false);
              resetForm();
              close();
              props.getDetails(props?.customerData?.uniqueCustomerRefId);
            } else {
              let error_msg = result?.error?.message ?? "Something went wrong.";

              props.openAlertPopup({
                message: error_msg,
                image: IMAGES.error,
                isFormOpen:true
              });
             setIsSubmit(false);
             close();
            }
          })
          .catch((error) => {
            props.openAlertPopup({
              message: "Something went wrong.",
              image: IMAGES.error,
              isFormOpen:true
            });
          setIsSubmit(false);
          close();
          });
            }}
          >
            {({
              isValid,
              dirty,
              setFieldValue,
              values,
              handleChange,
              handleBlur,
            }) => (
              <Form className={styles.form}>
                {accountManager?.length !== 0 ? (
                <fieldset>
                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <SelectField
                                      title={"Account Manager"}
                                      name="accountManager"
                                      onChange={(value) => {
                                        setFieldValue(
                                          "accountManagerEmail",
                                          value
                                        );
                                      }}
                                      options={accountManager}
                                      customStyles={styles.obj}
                                      placeholder="Enter name"
                                      additionalStyle={styles.additionalStyle}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Account Manager Email Address"
                                      name="accountManagerEmail"
                                      type="text"
                                      value={values.accountManagerEmail}
                                      placeholder=""
                                      readOnly
                                      disabled = {true}
                                    />
                                  </div>
                                </div>
                </fieldset>
          ): (<div className={styles.accountnotfount}>All Account Managers are Already Added</div>)}
              {accountManager?.length !== 0 ? (
                <div>
                  <hr className={styles.separator} />
                  <ButtonWrapper>
                    <BackButton
                      className={styles.backButton}
                      style={styles.submit}
                      title={"Back"}
                      closePopup={close}
                    />
                    <Submit
                     
                      title={"Submit"}
                      isSubmitting={isSubmit}
                      disabled={ !dirty}
                    />
                  </ButtonWrapper>
                </div>
            ): null}
              </Form>
            )}
          </Formik>
        </Sidebar>
      )}
    </div>
  );
};



const mapStateToProps = (state) => {
    return {
         accountManagerList: state.onboardingRequest.accountMManagerList.data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        },
        getAccountManagerList: (payload)=>{
            return dispatch(getAccountManagerList(payload))
        },
        AddNewAccountManager: (payload)=>{
            return dispatch(AddNewAccountManager(payload))
        },
        getDetails: (customerID) => {
            return dispatch(getCustomerDetails(customerID))
        },
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(AddAccountManager);

