import React, { useEffect, useRef, useState,useMemo } from "react";
import styles from "../graphs.module.css";
import * as echarts from 'echarts';
import { diameterJSON } from "../diameterJSON"
import LineChart from "../../../../components/charts/LineChart";
import { Formik, useField, Form, Field } from 'formik';
import { Header, SelectField, TimeGranularity, TextField, TimeWindowField } from "../component/component";
import moment from 'moment';
import { getUniqueValues,processInOutData } from "../utlis";
import { connect } from "react-redux";
import { getTotalInOutMessageData, updateTotalInOutFilters } from "../../../../redux/actions/dashboard/sigtran/totalInOutMesg.action";
import * as Constants from "../constants";
import Overlay from "../overlay/Overlay";
import * as Yup from "yup";
import { Loader } from "../../../../components/loader/Loader";
import IMAGES from "../../../../assets/images/images";
import {USER_TYPE} from "../../../../utils/constants/userConstants";
import { Graph_Name, Graphs } from "../../../../utils/constants/insightsConstants";
import { getLabelFromValue } from "../../../../utils/arrayUtils";




const Title = () => {
    return (
        <div className={styles.title}>Incoming MSUs and Outgoing MSUs</div>
    )
}
const ReadOnlyFilters = ({filter,...props}) => {
    return (
        <React.Fragment>
            <div className={styles.readonlyContainer}>
                <div className={styles.readonlyItem}>
                    <div className={styles.sectionTitle}>Time Granularity</div>
                    <div className={styles.readonlyValue}>{filter?.time_granularity}</div>
                </div>
                <div className={styles.readonlyItem}>
                    <div className={styles.sectionTitle}>Time window</div>
                    <div className={styles.readonlyValue}>{filter?.time_window}</div>
                </div>
            </div>
            {
                filter?.time_window === Constants.CUSTOM ?
                    <div className={styles.readonlyContainer}>
                        <div className={styles.readonlyItem}>
                            <div className={styles.sectionTitle}>Start date</div>
                            <div className={styles.readonlyValue}>{filter?.start_date}</div>
                        </div>
                        <div className={styles.readonlyItem}>
                            <div className={styles.sectionTitle}>End date</div>
                            <div className={styles.readonlyValue}>{filter?.end_date}</div>
                        </div>
                    </div> : <></>
            }
        </React.Fragment>
    )
}

const FilterContainer = ({ filter,defaultFilters,resetFilters,applyFilters,finaltype_finalDist_data, ...props }) => {
    let[isReset,setIsReset]=useState(true);
    const[uniqueDestinationOptions,setUniqueDestination]=useState([{name: "All", value: "All"}]); // All - is display all the data coming from api
    let _finalTypeOptions = ["All",...getUniqueValues(finaltype_finalDist_data, "final_type")];
    let finalTypeOptions  = _finalTypeOptions.map(key => ({name: key, value: key}));
    let _initialValues = filter;
    const TimeGranularityOption = [Constants.MINUTE15, Constants.HOUR, Constants.DAY, Constants.MONTH]
    const timeWindowOption = Constants.TIME_WINDOW_OPTIONS;
    const validationSchema = Yup.object({
        time_granularity: Yup.string().required("Required"),
        time_window: Yup.string().required("Required"),
        end_date: Yup.date().when("time_window", (time_window, schema) => {
            if (time_window == Constants.CUSTOM) {
                return schema.required("Required.").min(
                    Yup.ref('start_date'),
                    "End date can't be before Start date."
                ).test(
                    {
                        name:'max',
                        message:'End date can not be more than six months after the start date.',
                        test:function(value){
                            let sixMonthLaterDate = new Date(this.resolve(Yup.ref('start_date')));
                            sixMonthLaterDate.setMonth(sixMonthLaterDate.getMonth() + 6);
                            return new Date(value) <= sixMonthLaterDate
                        }
                    }
                )
            }
            return schema
        }),
        start_date: Yup.date().when("time_window", (time_window, schema) => {
            if (time_window == Constants.CUSTOM) {
                return schema.required("Required")
            }
            return schema
        })
    });
    useEffect(()=>{  // compare default and applied filters to enable reset button
        let obj1={
            time_granularity:filter?.time_granularity,
            time_window:filter?.time_window,
            end_date:filter?.end_date,
            start_date:filter?.start_date,
            final_type:filter?.final_type,
            final_destination:filter?.final_destination

        }
        let obj2={
            time_granularity:defaultFilters?.time_granularity,
            time_window:defaultFilters?.time_window,
            end_date:defaultFilters?.end_date,
            start_date:defaultFilters?.start_date,
            final_type:defaultFilters?.final_type,
            final_destination:defaultFilters?.final_destination
        }
        if(JSON.stringify(obj1) === JSON.stringify(obj2)){
            setIsReset(true);
        }else{
            setIsReset(false);
        }

        handleTypeChange(filter?.final_type); 

    },[filter])

    const handleTypeChange=(value)=>{
        if(value==="All"){
            setUniqueDestination([{name: "All", value: "All"}])
        }else{
                let _finalDestination=finaltype_finalDist_data?.filter((item) => {
                    return item["final_type"] == value;
                })

                let _uniqueDestination= ["All",...getUniqueValues(_finalDestination, "final_destination")];
                let uniqueDestination1  = _uniqueDestination.map(key => ({name: key, value: key}));
                setUniqueDestination(uniqueDestination1)
        }

    }

    const handleDestinationChange=(value)=>{
    }

    return (
        <div>
            <Formik
                initialValues={_initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    //let end_date = moment().format('YYYY-MM-DD');
                    //let dateFrom = moment().subtract(1, 'months').format('YYYY-MM-DD');  // d, 
                    if(values.time_window !==Constants.CUSTOM){
                        values.end_date=moment().format('YYYY-MM-DD');
                        if(values.time_window===Constants.LAST_24_HOURs){
                            values.start_date=moment().subtract(1, 'd').format('YYYY-MM-DD');
                        }
                        if(values.time_window===Constants.DAY1){
                            values.start_date=moment().subtract(1, 'd').format('YYYY-MM-DD');
                        }
                        if(values.time_window===Constants.DAYS7){
                            values.start_date=moment().subtract(7, 'd').format('YYYY-MM-DD');
                        }
                        if(values.time_window===Constants.MONTH3){
                            values.start_date=moment().subtract(3, 'months').format('YYYY-MM-DD');
                        }
                        if(values.time_window===Constants.MONTH6){
                            values.start_date=moment().subtract(6, 'months').format('YYYY-MM-DD');
                        }
                    }
                    applyFilters(values);
                }}
            >
                {({ isValid, dirty, values,setFieldValue }) => (

                    <Form>
                        <div className={styles.sectionContainer}>
                            <div  className={styles.sectionTitle}>Time</div>
                        </div>
                            <React.Fragment>
                                <TimeGranularity title={"Select Time Granularity"}name="time_granularity" options={TimeGranularityOption} />
                                <TimeWindowField
                                    title={"Time Window"}
                                    name="time_window"
                                    options={timeWindowOption}
                                />
                                {
                                    values?.time_window == Constants.CUSTOM ?
                                        <React.Fragment>
                                            <TextField
                                                label="Start Date"
                                                name="start_date"
                                                type="date"
                                                placeholder="Top Destinations"
                                            />
                                            <TextField
                                                label="End Date"
                                                name="end_date"
                                                type="date"
                                                placeholder="Top Destinations"
                                            />
                                        </React.Fragment> : <></>
                                }
                            </React.Fragment>

                        {
                           filter?.user_type===USER_TYPE.INTERNAL?
                            <React.Fragment>
                                 <SelectField
                                    title={"Final Type"}
                                    name="final_type"
                                    options={finalTypeOptions}
                                    onChange={(value)=>{
                                        setFieldValue("final_destination", "All");
                                        handleTypeChange(value,setFieldValue);
                                    }}
                                />
                                <SelectField
                                    title={"Final Destination"}
                                    name="final_destination"
                                    options={uniqueDestinationOptions}
                                    onChange={handleDestinationChange}
                                />
                            </React.Fragment>:<></>
                        }
                       
                         <div className={styles.formikBtnContainer}>
                           <button className={styles.formikBtnClear} disabled={isReset} onClick={resetFilters}>Reset</button>
                           <button  type="submit" className={styles.formikBtnSubmit}>Apply</button>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

const SelectedFilters=({selectedFiletrs,...props})=>{
    return(
        <div className={styles.selectedFilters}>
        <div className={styles.selectedItem}>
            <span className={styles.label}>Time Granularity</span><span className={styles.value}>{selectedFiletrs?.time_granularity}</span>
        </div> 
        <div className={styles.selectedItem}>
            <span className={styles.label}>Time Window</span><span className={styles.value}>{getLabelFromValue(Constants.TIME_WINDOW_OPTIONS,selectedFiletrs?.time_window)}</span>
        </div>  
        {
             selectedFiletrs?.time_window==Constants.CUSTOM?
            <React.Fragment>
                <div className={styles.selectedItem}>
                    <span className={styles.label}>Start Date</span><span className={styles.value}>{selectedFiletrs?.start_date}</span>
                </div> 
                <div className={styles.selectedItem}>
                    <span className={styles.label}>End Date</span><span className={styles.value}>{selectedFiletrs?.end_date}</span>
                </div> 
            </React.Fragment>:<></>
        }{
            selectedFiletrs?.user_type===USER_TYPE.INTERNAL?
            <React.Fragment>
                <div className={styles.selectedItem}>
                    <span className={styles.label}>Final Type</span><span className={styles.value}>{selectedFiletrs?.final_type}</span>
                </div> 
                <div className={styles.selectedItem}>
                    <span className={styles.label}>Final Destination</span><span className={styles.value}>{selectedFiletrs?.final_destination}</span>
                </div>  
            </React.Fragment>:<></>
        }
    </div>
    )
}
const SigtranInOutMessageGraph = (props) => {
    let [result,setResult]=useState({});
    const childRef = useRef();
    const legendData = [Constants.IN_MSUs, Constants.OUT_MSUs, Constants.TOTAL_MSUs];

    let defaultFilters={
        time_granularity: Constants.MINUTE15,
        time_window: Constants.DAYS7,
        final_type: "All",
        final_destination: "All",
        start_date:moment().subtract(7, 'd').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        user_type:props.myRights?.userType,
        cp_name:props.myRights?.cpName,
        granularityType:"minute"
    }

    useEffect(()=>{
        props.updateTotalInOutFilters(defaultFilters);
        let _filters=formatFiltersforAPI(defaultFilters)
        props.getTotalInOutMessageData(_filters);  // get data
    },[])

    useEffect(()=>{
        let _result =processInOutData(props.data,legendData,props.filters?.time_granularity);
        setResult(result=_result);
    },[props.data])

    const resetFilters=()=>{
        props.updateTotalInOutFilters(defaultFilters);
        let _filters=formatFiltersforAPI(defaultFilters)
        props.getTotalInOutMessageData(_filters);
        childRef.current.handleClick();
    }
    const applyFilters=(filter)=>{  
        props.updateTotalInOutFilters(filter);
        let _filters=formatFiltersforAPI(filter)
        props.getTotalInOutMessageData(_filters);
        childRef.current.handleClick();
    }

    const formatFiltersforAPI=(filters)=>{
        let obj={};

        if(filters?.time_granularity === Constants.MINUTE15){
            obj.time_granularity={
                minute:15
            }
            obj.granularityType="minute";
        }
        if(filters?.time_granularity === Constants.HOUR){
            obj.time_granularity={
                hour:1
            }
            obj.granularityType="hour";
        }
        if(filters?.time_granularity === Constants.MONTH){
            obj.time_granularity={
                month:1
            }
            obj.granularityType="month";
        }
        if(filters?.time_granularity === Constants.DAY){
            obj.time_granularity={
                day:1
            }
            obj.granularityType="day";
        }
        obj.time_window=filters?.time_window;
        obj.start_date=filters?.start_date;
        obj.end_date=filters?.end_date;
        obj.user_type=filters?.user_type;
        obj.cp_name=filters.cp_name;
        obj.final_type=filters.final_type;
        obj.final_destination=filters.final_destination
        return obj
    }
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <Title />
                <Overlay ref={childRef} >
                    <FilterContainer 
                        filter={props.filters} 
                        defaultFilters={JSON.parse(JSON.stringify(defaultFilters))}
                        resetFilters={resetFilters}
                        applyFilters={applyFilters}
                        finaltype_finalDist_data={props.finaltype_finalDist_data}/>
                </Overlay>
            </div>
            <SelectedFilters selectedFiletrs={props.filters}/>
            <div className={styles.subContainer}>
            {
                props.isLoading?<Loader/>:
                result?.xAxis_array?.length ?
               <LineChart
                style={styles.graphContainer}
                legend={legendData}
                xAxisData={result?.xAxis_array}
                series={result?.series}
                YAxisName={"MSUs (in number)"}
                grpahType={Graphs.SIGTRAN}
                graphName={Graph_Name.In_Out_MSU}
            />:<div className={styles.noSelectionMessage}>No Data.</div>
            }
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        filters: state.sigtranDashboard.totalInOutMessages.filters,
        data: state.sigtranDashboard.totalInOutMessages.data,
        isLoading: state.sigtranDashboard.totalInOutMessages.isLoading,
        finaltype_finalDist_data:state.sigtranDashboard.finalTypeFinalDist.finalType_finalDist,
        myRights:state.myRights.myRights,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateTotalInOutFilters: (filter) => {
            return dispatch(updateTotalInOutFilters(filter))
        },
        getTotalInOutMessageData: (filter) => {
            return dispatch(getTotalInOutMessageData(filter))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SigtranInOutMessageGraph);

