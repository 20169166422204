import { Link, useParams, useSearchParams } from "react-router-dom";
import IMAGES from "../../../../../../assets/images/images";
import { ChildContainer,Container } from "../../../../../../components/Container/Containers";
import { FormFieldLabel as Label, FormErrorLabel as ErrorLabel, FormFieldLabel } from "../../../../../../components/label/Labels";
import { Search } from "../../../../../../components/search/search";
import styles from "./onboardingApprovalListViewer.module.css";
import React, { useCallback, useEffect, useState} from "react";
import { formatSecondsToDate } from "../../../../../../utils/dateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../../../components/loader/Loader";
import { EmptyContainer } from "../../../../../dashboard/graphs/component/component";
import { useNavigate } from "react-router-dom";
import DropDown from "../../../../../../components/dropDown/DropDown";
import { useField } from "formik";
import { dueDate, formatDate, getLabelFromValue } from "../../../../../../utils/arrayUtils";
import { FUNCTIONAL_ROLES_CONSTANTS, USER_TYPE } from "../../../../../../utils/constants/userConstants"
import { ON_BOARDING_STATUS } from "../../../../../../utils/constants/userManagementConstant";

const OnboardingApprovalList = ({data, myRigths}) => {
    const navigate = useNavigate();
    const gotoDetail = (requestId) => {
      navigate(`/OnboardingDetail/${requestId}`);
    }

    return (
  <> 
       <div className={styles.container}>
         <div className={styles.head}>
              <div className={styles.subhead}>
                    <div className={styles.orgname} onClick={()=>gotoDetail(data.id)}>{data?.requestId}</div>
                    <div className={`${styles.status} ${styles[data?.requestStatus?.value]}`}>{data?.requestStatus?.label}</div>
              </div>
              <div className={styles.indicator}>
               <span className={styles.notificationmain}>
                {(data.isNewComment)&&(
                  <div className=""><span className={styles.commentnotification}></span>
                  <img className={styles.newcomment}src={IMAGES.newcomment} alt="new comment"/>
                  <img className={styles.notificationicon}src={IMAGES.vfPops} alt="notification"/> 
                  </div>
                 )}
               </span>
                 {/* <img onClick={()=>gotoDetail(data.id)} className={styles.gotologo} src={IMAGES.arrowright} alt="" /> */}
              </div>
         </div>
         <div className={styles.listContent}>
             <div className={styles.list}>
                <div className={styles.label}>Organization Legal Name</div>
                <div className={styles.value}>{data?.displayName}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Service Type</div>
                <div className={styles.value}>{data?.requestDetails?.map((item)=>item?.serviceType?.label).join(' & ')}</div>
             </div>
             {(myRigths?.functionalRoles[0] === FUNCTIONAL_ROLES_CONSTANTS["head_of_sales_internal"]?.value ||
               myRigths?.functionalRoles[0] === FUNCTIONAL_ROLES_CONSTANTS["head_of_commercial_internal"]?.value) &&(
              <>
              {(data?.requestStatus?.value===ON_BOARDING_STATUS.APPROVED ||data?.requestStatus?.value===ON_BOARDING_STATUS.REJECTED)&&(
               <div className={styles.list}>
                <div className={styles.label}>{data?.requestStatus?.value===ON_BOARDING_STATUS.APPROVED?"Approved By":"Rejected By"}</div>
                <div className={styles.value}>{data?.modifiedBy?.name}</div>
               </div>
             )}
              </>
             ) }
             <div className={styles.list}>
                <div className={styles.label}>Requested On</div>
                <div className={styles.value}>{formatDate(data?.createdAt)}</div>
             </div>
             <div class={styles.spacer}></div>
             {data?.requestStatus?.value===ON_BOARDING_STATUS.PENDING?
             myRigths?.functionalRoles[0] === FUNCTIONAL_ROLES_CONSTANTS["commercial_manager_internal"]?.value &&(
             <div>
              {data?.escalationDue?.status&&(<span className={styles.exclated}>Request Escalated</span>)}
              <span className={styles.duedate}>Due by {dueDate(data?.createdAt)}</span></div>
            )
             :<></>}
         </div>           
       </div>
  </>
    );
  };
  

const OnboardingApprovalListViewer = (props) => {
    const FieldWrapper = ({ children }) => {
        return <div className={styles.fieldWrapper}>{children}</div>;
      };

    const SelectField = ({ title, options, ...props }) => {
        // let _options = options.map((item) => ({
        //   value: item.value,
        //   label: item.label,
        // }));
       // const [field, meta, helpers] = useField(props);
    
    
        //const hasError = meta.touched && meta.error ? true : false;
        const obj = {
            width: "249px",
        }
        return (

          <FieldWrapper>
            {/* <div>
              <Label text={title} style={styles.labelcompLevel}/>
            </div> */}
            <DropDown
              //options={_options}
            //   customStyles={props.customStyles}
            //   onChange={({ label, value }) => {
            //     helpers.setValue(value);
            //   }}
            //   value={
            //     _options && field.value
            //       ? _options.find((option) => option.value === field.value)
            //       : ""
            //   }
            //   hasError={hasError}
            customStyles ={obj}
            //   isDisabled={props.isDisabled}
            />
            {/* {hasError ? <ErrorLabel text={meta.error} /> : null} */}
          </FieldWrapper>
        );
      };

      useEffect(() => {
        props.getOnBordingApprovalList();
      }, []);

      const fetchData = () => {
        if (props.requestList.next) {
          props.getOnBordingApprovalList(props.requestList.next);
        }
      };

  return (
<ChildContainer> 
    <div className={styles.searchhead}>
           {/* <div className={styles.search}>
             <Search 
                //  filterData={filterData}
                //  getSearchKeyValue={getSearchKeyValue}
                //  placeholder="Search"
                //  onKeyDown={onKeyDownHandler}
              />
            </div>
            <div className={styles.statussearch}>
              <SelectField
              title="Status" 
              />
            </div> */}
    </div>
    {props?.requestList?.isLoading && props?.requestList?.data?.length != 0 && <Loader />}
    {props?.requestList?.isLoading && props?.requestList?.data?.length == 0 ? (
        <Loader/>
      ) : props?.requestList?.data?.length == 0 ? (
        <div className={styles.Emptydiv}>No Records Found</div> 
      ) : (
        <InfiniteScroll
          dataLength={props?.requestList?.data?.length} //This is important field to render the next data
          next={() => {
            fetchData();
          }}
          hasMore={props?.requestList?.next}
          loader={<Loader />}
        >
          {props?.requestList?.data?.map((item) => (
             <OnboardingApprovalList  data={item} myRigths={props.myRights}/>
          ))}
        </InfiniteScroll>
      )}
    {/* {props?.requestList?.data?.map((item) => (
    <OnboardingApprovalList  data={item}/>
    ))} */}
</ChildContainer>
  );
};

export default OnboardingApprovalListViewer;
