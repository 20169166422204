import { Sidebar } from "../../../../components/sidebar/Sidebar";
import styles from "./addServiceRequest.module.css";

import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField } from "formik";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import { FormTextArea, TextInput } from "../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../components/button/buttons";
import FileAttachment from "../../../../components/fileAttachment/fileAttachment.container";
import { DetailContainer } from "../../../../components/comment/comment";
import Asterisk from "../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../assets/images/images";
import * as DOMPurify from "dompurify";
import IPX from "./ipxServiceRequest/ipxServiceRequest";
import IVC from "./ivcServiceRequest/ivcServiceRequest";
import Tooltip from "../../../../components/toolTip/toolTip";
import DropDown from "../../../../components/dropDown/DropDown";
import { ServiceRequestTypes,SERVICE_REQUEST_TYPES_VALUES } from "../../../../utils/constants/incidentConstants";

const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
export const Wrapper = ({ children }) => {
  return <div className={styles.wrapperContainer}>{children}</div>;
};
const AddServiceRequest = (props) => {
  const [show, setShow] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);

  let availedService =props?.myRights?.customerUserMetaInfo?.myServices?.map((service) => service?.serviceType);
  const Formtype =  availedService?.length > 1 ? "both" : availedService[0];
  
  const close = () => {
    setShow(false);
    setSelectedForm(null);
  };
  let failuerTypeDropdownDropObj = {
    width: "300px"
}
const FailureSelectField = ({ label, options, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    
    return (
        <>
            <div className={styles.FailuerTypeDropdownLabel}>{label}</div>
            {
   <>
                    <div className={styles.FailuerTypeDropdown}>
                        <DropDown
                            options={options}
                            onChange={({ label, value }) => {
                                props.onChange(value);
                                helpers.setValue(value);
                            }}
                            value={
                                ((options?.length > 0) && field.value)
                                    ? options.find((option) => option.value === field.value)
                                    : ""
                            }
                            hasError={hasError}
                            isDisabled={props.isDisabled}
                            customStyles={failuerTypeDropdownDropObj}
                            placeholder={props.placeholder}
                        />
                    </div>
                    {hasError ? <ErrorLabel text={meta.error} /> : null}
                </> 
            }
        </>
    );
};
const renderFormComponent = () => {
  switch (selectedForm) {
    case SERVICE_REQUEST_TYPES_VALUES.International_Voice_Services:
      return <IVC onClose={close} selectedForm={selectedForm} />;
    case SERVICE_REQUEST_TYPES_VALUES.IPX_Services:
      return <IPX onClose={close} selectedForm={selectedForm} />;
    default:
      return null;
  }
};

  return (
    <>
      <button onClick={() => setShow(true)} className={styles.continue}>
        Raise Service Request
      </button>
      {show && (
        <Sidebar
          isOpen={show}
          headerTxt="Raise a Service Request"
          onClose={close}
          sideNavStyle={styles.sideNavStyle}
        >
          {Formtype === SERVICE_REQUEST_TYPES_VALUES.IPX_Services ? (
            <IPX onClose={close} selectedForm={Formtype}/>
          ) : Formtype === SERVICE_REQUEST_TYPES_VALUES.International_Voice_Services ? (
            <IVC onClose={close} selectedForm={Formtype}/>
          ) : (
            <div>
            <Formik
              initialValues={{
                serviceType: "",
                description: "",
              }}
              onSubmit={(values) => {
                console.log(values);

              }}
            >           
            <Form>
               <Wrapper>
                   <FailureSelectField
                      label={"Service Type"}
                      name="service_type"
                      placeholder={"Select a service type"}
                      options={ServiceRequestTypes}
                      onChange={(value) => {
                      setSelectedForm(value);
                      }}
                      />
                </Wrapper>
            </Form>
            </Formik>
              {renderFormComponent()}
             </div>
          )}
        </Sidebar>
      )}
    </>
  );
};

export default AddServiceRequest;