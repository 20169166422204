import styles from "./bannerImage.module.css";
import banner from "../../../assets/images/banner.png";
import { SubmitButton } from "../../../components/button/buttons";

function BannerImage(props) {
  return (
    <div className={styles.imagecontainer}>
      <img src={banner} alt="Logo" />
      <p className={ props.canCreateNewService ? styles.bannerTxt : styles.bannerText}>{props.isInternalUser?"Connectivity":"My Connectivity"}</p>
      {
        props.canCreateNewService ?
          <SubmitButton
            type="button"
            style={styles.submitButton}
            onClick={props.openForm}
          >
            Raise New Demand
          </SubmitButton>
           : <></>
      }
    </div>
  );
}
export default BannerImage;
