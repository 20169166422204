import styles from "./comment.module.css"
import {SERVICE_OPTIONS, SERVICE_STATUS_OPTIONS} from "../../utils/constants/serviceConstants";
import { useNavigate } from "react-router-dom";
import { isString } from "../../utils/utils";

export const CommentContainer = ({children, id})=>{
    return (
        <div className={styles.commentContainer} id={id}>{children}</div>
    );
}

export const CommentCreator = ({creatorName, createdDate}) => {  
    return (
        <div className={styles.commentHeaderText} >
            <div className={styles.footerLabel}><span>Posted By: </span><span>{creatorName}</span></div>
            <div className={styles.footerLabel}><span>Posted On: </span><span>{createdDate}</span></div>
        </div>
    );
}

export const CommentContent = ({comment})=>{
    return (
        <div className={styles.commentText}>{comment}</div>
    );
}

export const AttachmentContainer = ({children})=>{
    return (
        <div className={styles.attachmentSection}>{children}</div>
    );
}

export const DetailContainer = ({data}) => {
  const obj = SERVICE_STATUS_OPTIONS.find(({ value }) => value ===data.status);
    return (
      <div className={styles.topSection}>
        <div className={styles.topSectionDiv}>
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Service:&nbsp;</div>
            <div className={styles.servcevalue}>{data.service}</div>
            </div>
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Organisation:&nbsp;</div>
            <div className={styles.servcevalue}>{data.organization}</div>
            </div>
          
        </div>
        <div className={styles.topSectionDiv}>
        <div className={styles.requestorDetailsDiv}>
          <div className={styles.servcelabel}>Requestor:&nbsp;</div>
          <div  className={styles.requestorvalue}>{isString(data.createdBy)?data.createdBy:data.createdBy?.name}</div></div>
          <div className={styles.requestorDetailsDiv}>
          <div className={styles.servcelabel}>Demand ID:&nbsp;</div>
          <div className={styles.servcevalue}>{data.requestId}</div></div>
        </div>
        <div className={styles.topSectionDiv}>
        <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Region:&nbsp;</div>
            <div className={styles.servcevalue}>{data?.locationDetails?.serviceRegion?.label}</div>
          </div> 
          <div className={styles.requestorDetailsDiv}>
            <div className={styles.servcelabel}>Country:&nbsp;</div>
            <div className={styles.servcevalue}>{data?.locationDetails?.serviceCountry?.label}</div>
          </div>
        </div>
        <div className={styles.bottomSectionDiv}>       
            <div className={styles.servceDetailDiv}>
              <div className={styles.servcelabel}>Status:&nbsp;</div>
              <div className={styles.servcevalue}>{obj?.label}</div>
            </div>
          {           
            Object.entries(data.service_option).map((option, index) => {
              return (
                <div className={styles.servceDetailDiv}>
                <div className={styles.servcelabel}>{SERVICE_OPTIONS[option[0]]}:</div>
              <div className={styles.servcevalue}>{(typeof option[1] === 'object' && option[1] !== null) ? option[1].toString() : option[1]}</div>
              </div>
              );
            })
          }
        </div>
  
  
      </div>
    );
  }



