import React, { useEffect, useState } from "react";
import { ChildContainer, Container } from "../../components/Container/Containers";
import { Banner } from "../../components/banner/Banners";
import { Breadcrumbs } from "../../components/breadcrumbs/Breadcrumbs";
import IMAGES from "../../assets/images/images";
import { Tabs } from "../../components/tabs/Tabs";
import CustomerViewer from "./customer/list/CustomerViewer.container";
import VFUSerViewer from "./vfUser/list/VFUserViewer.container";
import OnboardingListViewer from "./customer/myRequest/onboardingApproval/list/onboardingApprovalList.container";
import CAN from "../../casl/can";
import { Action, Resource } from "../../casl/constants";
import { connect } from "react-redux";
import { My_Request_SUBTAB, UserManagementTab } from "../../utils/constants/userManagementConstant";
import OnboardingRequestListViewer from "./customer/myRequest/onboardingRequest/list/onboardingRequestList.container";
import AccountRequestListViewer from "./customer/myRequest/accountAccessRequest/list/accountRequestList.container";
import AccountApprovalViewer from "./customer/myRequest/accountAccessApproval/list/accountApprovalList.container";
import OnboardingApprovalListViewer from "./customer/myRequest/onboardingApproval/list/onboardingApprovalList.container";
import { SubTabs } from "../../components/tabs/subtabs";
import styles from "./userManagement.module.css";
import { FUNCTIONAL_ROLES_CONSTANTS } from "../../utils/constants/userConstants";
import { resetTabs, setActivePrimaryTab, setActiveSecondaryTab } from "../../redux/actions/userManagement/userManagementTab.action";
const UserManagement = (props) => {
  // const [activeTab, setActiveTab] = useState("");
  // const [subActiveTab, setSubActiveTab] = useState("");
  const breadcrumbsOption = [
    { label: "User Management", link: "" }
  ];

  const tabOptions = [
    {
      key: UserManagementTab.CUSTOMER,
      label: props?.myRights?.functionalRoles[0] === FUNCTIONAL_ROLES_CONSTANTS["account_manager_internal"]?.value?"My Customers":"Customers",
      canAccess: CAN(Action.LIST, Resource.CUSTOMERS),
    },
     {
        key: UserManagementTab.ON_BOARDING_APPROVALS,
        label: "Onboarding Approvals",
        canAccess: CAN(Action.LIST, Resource.CUSTOMER_ONBOARDING_APPROVAL) && props?.myRights?.functionalRoles.length==1 && props?.myRights?.functionalRoles[0]!=="super_admin_internal",
      },
    {
      key: UserManagementTab.MY_REQUEST,
      label:props?.myRights?.functionalRoles[0] === FUNCTIONAL_ROLES_CONSTANTS["account_manager_internal"]?.value?"My Requests":"Requests",
      canAccess: CAN(Action.LIST, Resource.CUSTOMER_ACCESSR_REQUEST) || CAN(Action.LIST, Resource.CUSTOMER_ACCESS_APPROVAL) || CAN(Action.LIST, Resource.CUSTOMER_ONBOARDING_REQUEST), 
    },
    {
      key: UserManagementTab.VF_GROUP_USER,
      label: "Vodafone Users",
      canAccess: CAN(Action.LIST, Resource.RIGHTS) && props?.myRights?.functionalRoles.length==1 &&  props?.myRights?.functionalRoles[0]=="super_admin_internal", 
    },
  ];
  const getActiveTab = (tab) => {
    // setActiveTab(tab);
    props.setActivePrimaryTab(tab)
  };
  // const defaultActiveTab = tabOptions.filter(tab => tab.canAccess);

    const subTabOptions = [
      {
        key: My_Request_SUBTAB.ON_BOARDING_REQUEST,
        label: "Onboarding Requests",
        canAccess: CAN(Action.LIST, Resource.CUSTOMER_ONBOARDING_REQUEST),
      },
      {
        key: My_Request_SUBTAB.ON_BOARDING_APPROVAL,
        label: "Onboarding Approvals",
        canAccess: CAN(Action.LIST, Resource.CUSTOMER_ONBOARDING_APPROVAL),
      },
      {
        key: My_Request_SUBTAB.EXTENSION_ACCESS_REQUEST,
        label: "Extension Access Requests",
        canAccess: CAN(Action.LIST, Resource.CUSTOMER_ACCESSR_REQUEST), 
      },
       {
          key: My_Request_SUBTAB.EXTENSION_ACCESS_APPROVAL,
          label: "Extension Access Approvals",
          canAccess: CAN(Action.LIST, Resource.CUSTOMER_ACCESS_APPROVAL),
        },
    ]

  // const tabOption = [subTabOptions.ON_BOARDING_REQUEST, subTabOptions.EXTENSION_ACCESS_REQUEST, subTabOptions.EXTENSION_ACCESS_APPROVAL];
    const getSubActiveTab = (tab) => {
    // setSubActiveTab(tab);
    props.setActiveSecondaryTab(tab);
  }
  // const defaultSubActiveTab = subTabOptions.filter(tab => tab.canAccess);
  return (
    <Container>
      <Banner image={IMAGES.banner} text={"User Management"}></Banner>
      {/* <ChildContainer>
        <Breadcrumbs options={breadcrumbsOption} />
      </ChildContainer> */}
      <Tabs option={tabOptions} defaultActiveTab={props.activePrimaryTab} getActiveTab={getActiveTab} />
      <ChildContainer>
        {
          props?.activePrimaryTab === UserManagementTab.CUSTOMER  ?
            <CustomerViewer /> :
            props?.activePrimaryTab === UserManagementTab.VF_GROUP_USER ?
              <VFUSerViewer /> :
              props?.activePrimaryTab === UserManagementTab.ON_BOARDING_APPROVALS ?
                <OnboardingListViewer /> :
                props?.activePrimaryTab === UserManagementTab.MY_REQUEST ?
                  <>
                   <SubTabs option={subTabOptions} defaultActiveTab={props?.activeSecondaryTab} getSubActiveTab={getSubActiveTab} />
                    <div className={styles.containerinner}>
                    {
                      props?.activeSecondaryTab===My_Request_SUBTAB.ON_BOARDING_REQUEST?
                       <OnboardingRequestListViewer/>:
                      props?.activeSecondaryTab===My_Request_SUBTAB.ON_BOARDING_APPROVAL?
                       <OnboardingApprovalListViewer/>:
                      props?.activeSecondaryTab===My_Request_SUBTAB.EXTENSION_ACCESS_REQUEST?
                       <AccountRequestListViewer/>:
                      props?.activeSecondaryTab===My_Request_SUBTAB.EXTENSION_ACCESS_APPROVAL?
                       <AccountApprovalViewer />: 
                      <></>
                    }
                    </div>
                  </> :<></>
        }
      </ChildContainer>
    </Container>
  );
};

const mapStateToProps = (state) => {
  // console.log("state",state)
    return { 
     myRights :state.myRights.myRights,
     activePrimaryTab :state.tabReducer.activePrimaryTab,
     activeSecondaryTab :state.tabReducer.activeSecondaryTab
    } 
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setActivePrimaryTab: (tab) => {
        return dispatch(setActivePrimaryTab(tab))
      },
      setActiveSecondaryTab: (tab) => {
        return dispatch(setActiveSecondaryTab(tab))
      },
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
