import { connect } from "react-redux";
import OnboardingRequestDetail from "./onboardingRequestDetail";
import { getOnBoardingRequestDetail } from "../../../../../../redux/actions/userManagement/onBoardingRequestDetail.action";
import { clearOnboadingComments, getOnBoardingComments } from "../../../../../../redux/actions/userManagement/onboardingAddComment.action";
import { downloadFiles } from "../../../../../../redux/actions/fileAttachment/fileAttachment.actions";
const mapStateToProps = (state) => {

  return {
    requestDetails : state.onboardingRequest.onBoardingRequestDetail,
    commentsList :state.onboardingRequest.onBordingCommentList
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getOnBoardingRequestDetail: (requestId)=>{
      return dispatch(getOnBoardingRequestDetail(requestId))
    },
    getOnBoardingComments:(requestId,next=null)=>{
      return dispatch(getOnBoardingComments(requestId,next))
    },
    clearOnboadingComments:()=>{
      return dispatch(clearOnboadingComments())
    },
    downloadFiles:(originalFileName,uniqueFileName)=>{
      return dispatch(downloadFiles(originalFileName,uniqueFileName))
  },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingRequestDetail);
